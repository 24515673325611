import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ProductBackground } from 'assets/svgs';
import { Button } from 'components';

function Indications() {
  const { product } = useStaticQuery(graphql`
    query {
      product: file(relativePath: { eq: "assets/images/product.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <InnerContainer
      id="indications"
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}>
      <Title>
        DNORD is indicated for the following
        <sup>
          <small>2</small>
        </sup>
      </Title>
      <MainContent>
        <ProductImage>
          <ProductBackground />
          <Image image={getImage(product)} alt="DNord 255 microgram" />
        </ProductImage>
        <TextContent>
          <Paragraph width="55%">
            <span>Treatment</span> of vitamin D deficiency
            <br />
            (i.e., 25(OH)D levels &#x3c; 25 nmol/L) in adults
          </Paragraph>
          <Paragraph width="85%">
            <span>Prevention</span> of vitamin D deficiency in adults with identified risks such as
            in patients with malabsorption syndrome, chronic kidney disease mineral and bone
            disorder (CKD-MBD) or other identified risks
          </Paragraph>
          <Paragraph width="65%">
            As <span>adjuvant</span> for the specific treatment of osteoporosis in patients with
            vitamin D deficiency or at risk of vitamin D deficiency
          </Paragraph>
        </TextContent>
      </MainContent>
      <Button
        onClick={() => window.open('/spc.pdf', '_blank')}
        color="var(--dnord-teritary)"
        download>
        Download SPC
      </Button>
      <References>
        1. Charoenngam N and Holick M F: Nutrients 2020, 12, 2097; doi:10.3390/ nu12072097 2. DNORD
        SPC;
        https://www.hpra.ie/img/uploaded/swedocuments/Licence_PA23343-001-001_15112022130834.pdf 3.
        Esteban Jódar E et al; Endocrine Abstracts (2021) 73 AEP83 | DOI: 10.1530/endoabs.73.AEP83
        Calcium and Bone 4. Pludowski P et al; Nutrients 2022, 14, 1483.
        https://doi.org/10.3390/nu14071483
      </References>
    </InnerContainer>
  );
}

const InnerContainer = styled(motion.section)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  justify-content: center;
  margin: 2rem 0;
  padding: 0 1.25rem;
  @media screen and (min-width: 64rem) {
    gap: 3rem;
    grid-column: 2/12;
    margin: 10rem 0 3rem 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const Title = styled.h3`
  color: var(--dnord-teritary);
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 2rem;
  width: 100%;
`;

const ProductImage = styled(motion.div)`
  align-self: center;
  height: 257px;
  min-width: 264px;
  position: relative;
  width: 264px;
  svg {
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 64rem) {
    height: 357px;
    min-width: 364px;
    width: 364px;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media screen and (min-width: 64rem) {
    flex-direction: row;
  }
`;

const Image = styled(GatsbyImage)`
  height: auto;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 300;
  gap: 2.25rem;
  width: 100%;
  span {
    color: var(--dnord-secondary);
    font-weight: 700;
  }
`;

const Paragraph = styled.p`
  position: relative;
  &:before {
    background: linear-gradient(90deg, #00544b 0%, #c9d688 43%, #ff8c1a 69%, #f46437 100%);
    bottom: -0.75rem;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    width: ${({ width }) => width};
  }
`;

const References = styled.span`
  color: #6d6e71;
  font-family: 'source-sans-pro', sans-serif;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  word-break: break-all;
`;

export default Indications;
