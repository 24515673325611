import * as React from 'react';
const SvgReminderTopFrame = (props) => (
  <svg viewBox="0 0 215 224" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M210.114 181.045V25.471C210.114 12.503 199.502 2 186.4 2H.64"
      stroke="url(#reminder-top-frame_svg__a)"
      strokeWidth={4}
      strokeMiterlimit={10}
    />
    <path d="M214.857 206.12h-10.059v17.701h10.059V206.12Z" fill="#00544B" />
    <path
      d="M214.857 188.262h-10.059v13.398h10.059v-13.398ZM214.857 210.599h-10.059v4.459h10.059v-4.459Z"
      fill="#002D26"
    />
    <defs>
      <linearGradient
        id="reminder-top-frame_svg__a"
        x1={214.066}
        y1={89.566}
        x2={0.64}
        y2={89.566}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#C9D688" />
        <stop offset={0.69} stopColor="#FF8C1A" />
        <stop offset={1} stopColor="#F46437" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgReminderTopFrame;
