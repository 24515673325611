import * as React from 'react';
const SvgLocation = (props) => (
  <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 5.92c0 4.08-5.054 9.524-5.054 9.524S1 10 1 5.92C1 3.303 3.258 1 6.054 1 8.849 1 11 3.198 11 5.92Z"
      stroke="#00544B"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      stroke="#00544B"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLocation;
