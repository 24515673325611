import * as React from 'react';
const SvgDownload = (props) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 12 3 7l1.4-1.45L7 8.15V0h2v8.15l2.6-2.6L13 7l-5 5Zm-8 4v-5h2v3h12v-3h2v5H0Z"
      fill="#fff"
    />
  </svg>
);
export default SvgDownload;
