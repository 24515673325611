import * as React from 'react';
const SvgEmail = (props) => (
  <svg viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.976 1h10.048c.586 0 .976.396.976.989v7.022c0 .593-.39.989-.976.989H1.976C1.39 10 1 9.604 1 9.011V1.989C1 1.396 1.39 1 1.976 1Z"
      stroke="#00544B"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 1.5 7 7 1.5 1.5M9 5l4 4M5 5 1 9"
      stroke="#00544B"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgEmail;
