import * as React from 'react';
const SvgOsteo = (props) => (
  <svg viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M64.34 10.904a6.936 6.936 0 0 0-5.854-2.535 6.884 6.884 0 0 0-2.545-5.831C53.442.508 49.765.484 47.246 2.49a6.903 6.903 0 0 0-1.75 8.778c.558.997.423 2.235-.386 3.04L34.706 24.675a2.374 2.374 0 0 0 0 3.362 2.397 2.397 0 0 0 2.24.632l2.935-.696-1.004 4.215 1.548 1.542 12.094-12.046c.809-.806 2.052-.94 3.052-.385a6.962 6.962 0 0 0 8.813-1.743c2.015-2.509 1.995-6.172-.044-8.657v.006ZM22.404 51.687l10.894-10.852a1.685 1.685 0 0 0 0-2.389 3.588 3.588 0 0 1-.96-3.378l.537-2.259-4.231 1-1.552-1.545-12.094 12.047c-.809.806-2.051.94-3.052.384a6.963 6.963 0 0 0-8.813 1.743c-2.015 2.51-1.995 6.172.047 8.658a6.945 6.945 0 0 0 5.854 2.536 6.884 6.884 0 0 0 2.546 5.83c2.498 2.031 6.176 2.054 8.694.047a6.9 6.9 0 0 0 1.75-8.778c-.557-.997-.423-2.235.386-3.04l-.006-.004ZM11.502 25.821h6.862M20.856 20.014l-4.726-4.707M26.87 17.197v-6.051"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgOsteo;
