import * as React from 'react';
const SvgMuscle = (props) => (
  <svg viewBox="0 0 80 67" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.852 14.29s17.851 19.89 25.264 32.953l-2.049 1.262c5.56-3.537 12.958-7.23 18.238-11.254.988-.753 1.087-2.273 1.497-3.442l.837-2.382a13.479 13.479 0 0 1 2.316-3.08l3.04-3.02c.502-.497 2.37 2.464 1.054 3.772L64.8 31.334l7.236-7.014c1.04-.992 3.891-1.487 5.202-.136 1.31 1.352 2.52 6.863.439 8.982l-5.24 5.205c-1.029 1.022-3.592 2.73-4.931 3.158-2.523.808-3.348 1.67-4.813 3.508 0 0-17.729 21.672-26.99 20.66-3.93-.43-24.01-23.81-34.483-38.175"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.382 19.258s4.487 2.625 9.421 7.853c6.083 6.44 4.406 11.54 6.266 16.716 1.528 4.25 4.295 6.227 7.015 7.08M33.137 54.448c-7.666-2.403-13.536-9.5-17.297-21.154-3.498-10.844-6.262-11.961-6.262-11.961"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.382 19.258s4.487 2.625 9.421 7.853c6.083 6.44 4.406 11.54 6.266 16.716 1.528 4.25 4.295 6.227 7.015 7.08M9.578 21.333s2.764 1.117 6.263 11.96c3.76 11.654 9.63 18.752 17.296 21.155M61.525 23.811l-12.78 2.325-.337-6.25-15.155 8.63 11.819-2.52.165 5.616 16.288-7.8ZM47.565 1.731l-7.806 10.342-4.504-4.362-5.26 16.578 6.969-9.836 3.946 4.015L47.565 1.73Z"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMuscle;
