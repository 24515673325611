import * as React from 'react';
import { motion } from 'framer-motion';

function SvgAccordianIcon(props) {
  return (
    <motion.svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.00024 10.9998L17.9939 10.8517"
        stroke={props.open ? '#E5E5E5' : 'var(--dnord-teritary)'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {!props.open && (
        <path
          d="M11.071 18.0708L11.2191 4.07717"
          stroke={props.open ? '#E5E5E5' : 'var(--dnord-teritary)'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      <circle
        cx="11"
        cy="11"
        r="10"
        stroke={props.open ? '#E5E5E5' : 'var(--dnord-teritary)'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
}
export default SvgAccordianIcon;
