import React, { useState, useRef, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import ModalContent from '../ModalContent';
import ModalTrigger from '../ModalTrigger';

function Modal({
  disableCloseOnClickOutside,
  hideCloseButton,
  modalContent,
  openWithoutTrigger,
  trigger
}) {
  const [isShown, setIsShown] = useState(openWithoutTrigger);
  const TriggerButton = useRef(null);
  const closeButton = useRef(null);
  const modal = useRef(null);

  useEffect(() => {
    setIsShown(openWithoutTrigger);
  }, [openWithoutTrigger]);

  const toggleScrollLock = () => {
    document.querySelector('html').classList.toggle('scroll-lock');
  };

  const showModal = () => {
    setIsShown(true);
    toggleScrollLock();
  };

  const closeModal = () => {
    setIsShown(false);
    document.querySelector('html').classList.remove('scroll-lock');
  };

  const onKeyDown = (event) => {
    if (event.key === 'Escape' && !disableCloseOnClickOutside) {
      closeModal();
    }
  };

  const onClickOutside = (e) => {
    if ((modal && modal.current.contains(e.target)) || disableCloseOnClickOutside) return;
    closeModal();
  };

  return (
    <>
      <ModalTrigger showModal={showModal} buttonRef={TriggerButton} trigger={trigger} />
      <AnimatePresence>
        {isShown ? (
          <ModalContent
            buttonRef={closeButton}
            closeModal={closeModal}
            content={modalContent}
            hideCloseButton={hideCloseButton}
            modalRef={modal}
            onClickOutside={onClickOutside}
            onKeyDown={onKeyDown}
            openWithoutTrigger={openWithoutTrigger}
          />
        ) : (
          null
        )}
      </AnimatePresence>
    </>
  );
}

export default Modal;
