import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Dnord, ProductBackground } from 'assets/svgs';

function Header({ graphic }) {
  const { product } = useStaticQuery(graphql`
    query {
      product: file(relativePath: { eq: "assets/images/product.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <InnerContainer id="what-is-dnord">
      <Content>
        <Title>
          What is <Dnord />
          <br />
          and what it is used for?
        </Title>
        <div>
          <ProductImage
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}>
            <ProductBackground />
            <Image image={getImage(product)} alt="DNord 255 microgram" />
          </ProductImage>
          <Paragraph
            initial={{ x: '-100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}>
            <Dnord />
            contains a form of vitamin D, calcifediol, which is used to treat vitamin D deficiency
            and derived problems. Vitamin D is involved in the human body, among other actions, it
            increases calcium absorption.
          </Paragraph>
        </div>
      </Content>
      <PersonMeshAnimationWrapper>
        <PersonMeshDesktop image={getImage(graphic)} alt="person mesh" />
      </PersonMeshAnimationWrapper>
    </InnerContainer>
  );
}

const InnerContainer = styled.header`
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  min-height: 100vh;
  padding: 0 1.25rem;
  position: relative;
  @media screen and (min-width: 64rem) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 0;
    max-height: 1152px;
  }
`;

const Content = styled.div`
  align-self: end;
  display: flex;
  flex-direction: column;
  gap: 2.875rem;
  grid-column: 1/7;
  margin-top: 6rem;
  padding-bottom: 3rem;
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media screen and (min-width: 64rem) {
      flex-direction: row;
      gap: 3rem;
    }
  }
  @media screen and (min-width: 64rem) {
    grid-column: 2/10;
    margin: 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/10;
  }
  @media screen and (max-height: 48rem) {
    margin-top: 9.5rem;
  }
`;

const Title = styled.h2`
  font-size: 3rem;
  font-style: italic;
  font-weight: 300;
  svg {
    width: 260px;
  }
  @media screen and (min-width: 23rem) {
    font-size: 3.75rem;
    svg {
      width: 320px;
    }
  }
  @media screen and (min-width: 64rem) {
    font-size: 4.25rem;
  }
`;

const ProductImage = styled(motion.div)`
  align-self: center;
  height: 257px;
  min-width: 264px;
  position: relative;
  width: 264px;
  z-index: -1;
  svg {
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 48rem) {
    align-self: flex-start;
    height: 357px;
    min-width: 364px;
    width: 364px;
  }
`;

const Image = styled(GatsbyImage)`
  height: auto;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
`;

const Paragraph = styled(motion.p)`
  font-size: 1.5rem;
  font-style: italic;
  width: 100%;
  svg {
    width: 98px;
    margin-right: 0.5rem;
  }
  span {
    color: var(--dnord-secondary);
    font-weight: 700;
  }
`;

const PersonMeshAnimationWrapper = styled(motion.div).attrs({
  initial: { opacity: 0, x: 200, y: 200 },
  animate: { opacity: 1, x: 0, y: 0 },
  transition: {
    delay: 0.5,
    ease: 'easeOut',
    opacity: { duration: 0.5 },
    x: { duration: 1 },
    y: { duration: 1 }
  }
})`
  bottom: -100px;
  right: 0;
  position: absolute;
  z-index: -1;

  @media screen and (max-height: 48rem) {
    bottom: -100px;
  }
`;

const PersonMeshDesktop = styled(GatsbyImage)`
  min-width: 64rem;
  width: 84.5vw;
  @media screen and (min-width: 48rem) {
    width: 100vw;
    min-width: 90rem;
  }
  @media screen and (min-width: 64rem) {
    max-width: 121.875rem;
    min-width: 75.938rem;
    width: 84.5vw;
  }
`;

export default Header;
