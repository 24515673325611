import * as React from 'react';
const SvgDnord = (props) => (
  <svg viewBox="0 0 99 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.26 0H8.196a.241.241 0 0 0-.222.15C6.315 3.677 7.4 6.598 9.034 11.166c.353.973.549 1.92.653 2.811 0 .041.052.041.052 0l1.752-8.682h7.632c1.424 0 2.378.343 1.842 2.99l-1.124 5.514c-.535 2.647-1.699 2.963-3.032 2.963H9.922a.248.248 0 0 0-.248.233c-.261 2.208-1.02 3.881-1.411 4.608-.092.179.026.398.222.398h8.494c4.731 0 7.018-2.84 8.077-8.133l1.123-5.61C27.277 2.935 24.99 0 20.26 0Z"
      fill="#002D26"
    />
    <path
      d="M1.65 8.504h1.124c.862 0 1.699.247 2.496.576a.725.725 0 0 0 .967-.48c.157-.535-.222-1.029-.706-1.029a.744.744 0 0 0-.3.069c-.915.466-1.96.59-2.98.59H1.65c-.078 0-.13.068-.13.15 0 .069.065.124.13.124Z"
      fill="url(#dnord_svg__a)"
    />
    <path
      d="M7.766 14.223a.725.725 0 0 0 .47-.494c.157-.534-.222-1.028-.705-1.028a.745.745 0 0 0-.3.068c-.929.44-1.974.563-2.994.563h-.6c-.08 0-.131.068-.131.15 0 .083.065.138.143.138h1.124c.863 0 1.7.247 2.496.576a.684.684 0 0 0 .497.027Z"
      fill="url(#dnord_svg__b)"
    />
    <path
      d="M2.67 5.774h.352c.68 0 1.346.206 1.986.453a.518.518 0 0 0 .353.014.582.582 0 0 0 .419-.44c.104-.41-.196-.795-.575-.795a.573.573 0 0 0-.262.069c-.614.329-1.346.411-2.052.411H2.67c-.078 0-.13.069-.13.151 0 .082.065.137.13.137Z"
      fill="url(#dnord_svg__c)"
    />
    <path
      d="M7.845 15.595a.572.572 0 0 0-.262.068c-.64.33-1.359.426-2.065.426h-.222c-.078 0-.13.068-.13.15 0 .083.065.138.143.138h.353c.68 0 1.346.205 1.987.452a.518.518 0 0 0 .352.014.582.582 0 0 0 .419-.439c.104-.439-.196-.81-.575-.81Z"
      fill="url(#dnord_svg__d)"
    />
    <path
      d="M3.832 3.045h.51c.366 0 .706.123 1.045.288a.43.43 0 0 0 .236.041c.222-.014.392-.206.405-.439.013-.274-.196-.507-.458-.507a.538.538 0 0 0-.156.027c-.445.178-.915.302-1.386.302h-.196c-.078 0-.13.068-.13.15 0 .07.052.138.13.138Z"
      fill="url(#dnord_svg__e)"
    />
    <path
      d="M7.492 18.489a.538.538 0 0 0-.157.027c-.445.179-.915.302-1.385.302h-.197c-.078 0-.13.069-.13.15 0 .083.065.138.144.138h.51c.365 0 .705.123 1.045.288a.43.43 0 0 0 .235.041c.222-.014.392-.206.405-.439 0-.288-.209-.507-.47-.507Z"
      fill="url(#dnord_svg__f)"
    />
    <path
      d="M5.23.466h.445c.261 0 .51.083.758.165.04.014.078.014.13.014a.314.314 0 0 0 .275-.288.316.316 0 0 0-.3-.357c-.04 0-.079.014-.118.027-.327.138-.693.165-1.045.165H5.23c-.079 0-.13.069-.13.15-.014.07.051.124.13.124Z"
      fill="url(#dnord_svg__g)"
    />
    <path
      d="M6.524 21.204c-.039 0-.078.014-.117.028-.327.137-.693.165-1.046.165h-.143c-.079 0-.131.068-.131.15 0 .083.065.137.144.137h.444c.261 0 .51.083.758.165.04.014.078.014.13.014a.314.314 0 0 0 .275-.288c.013-.206-.13-.37-.314-.37Z"
      fill="url(#dnord_svg__h)"
    />
    <path
      d="M6.525 11.85c.548 0 .993-.534.862-1.138-.078-.343-.34-.617-.666-.686a.843.843 0 0 0-.523.041c-.954.412-1.947.714-2.98.714H.644c-.079 0-.13.054-.144.137 0 .082.052.15.13.15H3.1c1.046 0 2.105.179 3.02.686.13.055.26.096.405.096Z"
      fill="url(#dnord_svg__i)"
    />
    <path
      d="M42.606 6.419h-1.855c-.314 0-.523.178-.588.48L37.98 17.666 32.073 6.789a.663.663 0 0 0-.627-.37h-1.908c-.314 0-.523.178-.588.48l-2.927 14.497c-.079.398.104.59.457.59h1.856c.313 0 .522-.178.588-.48l2.156-10.849 5.946 10.973c.144.26.353.37.627.37h1.895c.314 0 .523-.178.588-.48l2.928-14.498c.091-.411-.092-.603-.458-.603ZM55.623 6.419h-5.424c-3.476 0-5.044 2.071-5.724 5.39l-.967 4.828C42.855 19.93 44.266 22 47.755 22h5.41c3.49 0 5.071-2.071 5.725-5.363l.967-4.828c.692-3.319-.758-5.39-4.234-5.39Zm1.346 5.267-1.007 5.06c-.353 1.73-1.006 2.319-2.548 2.319H47.99c-1.529 0-1.934-.59-1.58-2.318l1.006-5.061c.379-1.77.993-2.346 2.548-2.346h5.41c1.581.014 1.96.576 1.594 2.346ZM88.686 6.419h-8.704c-.313 0-.522.178-.588.48l-1.882 9.3a.366.366 0 0 0 .353.452h2.183c.17 0 .313-.124.352-.302l1.425-7.009h6.286c1.646 0 2.313.508 1.83 2.935l-.798 3.91c-.51 2.427-1.411 2.893-2.993 2.893H72.808l-.654-1.412c2.457-.645 3.516-2.496 4.065-5.28l.078-.357c.758-3.731-.863-5.624-5.175-5.624h-7.319c-.313 0-.522.179-.588.48l-2.914 14.512c-.078.397.105.59.458.59h1.855c.314 0 .523-.179.588-.48l.72-3.594h5.344l1.516 3.662c.105.288.314.425.614.425h14.872c3.476 0 5.005-1.88 5.79-5.76l.797-3.978c.77-3.91-.706-5.843-4.17-5.843Zm-19.159 8.586h-5.03l1.15-5.65h5.004c2.287 0 3.202.438 2.692 2.687l-.052.302c-.523 2.263-1.529 2.661-3.763 2.661ZM94.501 8.997c0-1.303.902-2.194 2-2.194 1.097 0 1.999.891 1.999 2.194s-.902 2.195-2 2.195c-1.097 0-1.999-.892-1.999-2.195Zm3.58 0c0-1.042-.679-1.796-1.568-1.796-.888 0-1.58.74-1.58 1.796 0 1.057.692 1.784 1.58 1.784.89 0 1.569-.714 1.569-1.784ZM95.73 7.845h.81c.445 0 .837.192.837.727 0 .26-.157.522-.405.618l.483.918h-.483l-.366-.781h-.431v.781h-.431V7.845h-.014Zm.72 1.125c.287 0 .457-.137.457-.384 0-.206-.118-.37-.432-.37h-.3v.74h.274v.014Z"
      fill="#82BB56"
    />
    <defs>
      <linearGradient
        id="dnord_svg__a"
        x1={4.328}
        y1={0.261}
        x2={3.105}
        y2={21.341}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C9D688" />
        <stop offset={1} stopColor="#00544B" />
      </linearGradient>
      <linearGradient
        id="dnord_svg__b"
        x1={6.584}
        y1={0.392}
        x2={5.361}
        y2={21.472}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C9D688" />
        <stop offset={1} stopColor="#00544B" />
      </linearGradient>
      <linearGradient
        id="dnord_svg__c"
        x1={4.457}
        y1={0.269}
        x2={3.234}
        y2={21.349}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C9D688" />
        <stop offset={1} stopColor="#00544B" />
      </linearGradient>
      <linearGradient
        id="dnord_svg__d"
        x1={7.631}
        y1={0.453}
        x2={6.408}
        y2={21.533}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C9D688" />
        <stop offset={1} stopColor="#00544B" />
      </linearGradient>
      <linearGradient
        id="dnord_svg__e"
        x1={4.999}
        y1={0.3}
        x2={3.775}
        y2={21.38}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C9D688" />
        <stop offset={1} stopColor="#00544B" />
      </linearGradient>
      <linearGradient
        id="dnord_svg__f"
        x1={7.749}
        y1={0.46}
        x2={6.525}
        y2={21.54}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C9D688" />
        <stop offset={1} stopColor="#00544B" />
      </linearGradient>
      <linearGradient
        id="dnord_svg__g"
        x1={5.96}
        y1={0.356}
        x2={4.736}
        y2={21.436}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C9D688" />
        <stop offset={1} stopColor="#00544B" />
      </linearGradient>
      <linearGradient
        id="dnord_svg__h"
        x1={7.073}
        y1={0.421}
        x2={5.85}
        y2={21.5}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C9D688" />
        <stop offset={1} stopColor="#00544B" />
      </linearGradient>
      <linearGradient
        id="dnord_svg__i"
        x1={4.518}
        y1={0.272}
        x2={3.295}
        y2={21.352}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C9D688" />
        <stop offset={1} stopColor="#00544B" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgDnord;
