import * as React from 'react';
const SvgCancer = (props) => (
  <svg viewBox="0 0 49 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.407 42.429c-4.182-5.346-6.367-10.936-6.367-18.674 0-7.738 6.396-14.223 14.278-14.223M32.214 42.4c4.268 3.654 9.267 7.722 14.949 12.645L41.453 65c-7.369-6.387-12.167-9.914-17.134-14.394"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.35 13.796c.314 8.93 3.74 14.976 9.981 21.363"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.313 13.796c-.315 8.93-3.741 14.976-9.982 21.363-2.27 2.319-4.926 4.695-7.924 7.267C12.152 46.078 7.17 50.135 1.5 55.04l5.682 9.956c7.339-6.343 12.138-9.87 17.133-14.394 3.128-2.815 5.784-5.476 7.896-8.206 4.182-5.334 6.383-10.952 6.383-18.645 0-7.694-6.396-14.223-14.279-14.223"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.594 23.755 37.48 12.66a12.597 12.597 0 0 0-2.064-5.745A13.351 13.351 0 0 0 24.315 1"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.037 23.755 11.15 12.66a12.596 12.596 0 0 1 2.065-5.745A13.35 13.35 0 0 1 24.316 1"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCancer;
