import * as React from 'react';
const SvgFastResponse = (props) => (
  <svg viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={75} cy={75} r={50} transform="rotate(90 75 75)" fill="#82BB56" fillOpacity={0.3} />
    <path
      d="M95.086 54.36a31.67 31.67 0 0 1 4.814 4.358M65.843 53.33H76.17C90.986 53.33 103 65.348 103 80.165 103 94.983 90.986 107 76.171 107 61.357 107 49.33 94.997 49.33 80.165c0-3.658.728-7.158 2.071-10.345M71 48.173h-1.029v-3.101c0-1.143.929-2.072 2.072-2.072H80.3a2.06 2.06 0 0 1 2.057 2.072v3.1H78.23M67.9 61.59h-6.186m-8.257 0H45.2M39 53.33h18.586m16.4 29.036-10.2 11.231M76.17 61.604c10.258 0 18.572 8.316 18.572 18.576S86.429 98.755 76.17 98.755c-2.557 0-5-.514-7.228-1.457M73.07 80.18c0-1.715 1.386-3.101 3.1-3.101 1.715 0 3.1 1.386 3.1 3.1 0 1.715-1.385 3.101-3.1 3.101a3.098 3.098 0 0 1-3.1-3.1Z"
      stroke="#182F2D"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      style={{
        mixBlendMode: 'multiply'
      }}
      d="M30.597 119.135s2.217 2.189 5.827 5.129c3.617 2.953 8.985 6.152 14.666 8.655 5.768 2.319 11.841 3.762 16.477 4.299 2.3.399 4.27.272 5.623.412 1.36.077 2.137.119 2.137.119v4.978s-.838-.045-2.305-.121c-1.46-.146-3.583-.027-6.065-.447-5.002-.582-11.563-2.133-17.79-4.645-6.142-2.691-11.93-6.16-15.833-9.34-3.899-3.167-6.283-5.536-6.283-5.536l3.549-3.5-.003-.003Z"
      fill="url(#fast-response_svg__a)"
    />
    <path
      d="m99.933 3.87-4.095 11.841s1.804.656 3.61 1.309a70.769 70.769 0 0 1 3.472 1.633l5.521-11.251s-2.061-1.042-4.177-1.948l-4.33-1.581V3.87ZM124.592 18.003l-8.208 9.486s1.427 1.287 2.851 2.577c.751.606 1.329 1.386 1.833 1.901l.806.888 9.301-8.422-.971-1.063c-.599-.621-1.313-1.536-2.201-2.274l-3.413-3.093h.002ZM142.276 40.218l-11.138 5.794s.859 1.817 2.365 4.994c.563 1.666 1.403 3.635 2.119 5.972.531 2.387 1.478 5.007 1.783 8.021 2.291 11.836.244 28.747-8.304 42.494-4.175 6.881-9.747 12.895-15.775 17.443-6.041 4.528-12.401 7.801-18.174 9.588-2.865.931-5.573 1.576-7.97 2.075-2.437.307-4.535.793-6.304.867-3.533.183-5.553.286-5.553.286v4.38s2.028-.105 5.58-.285c3.543-.262 8.563-1.076 14.421-2.726 5.769-1.919 12.314-4.795 18.573-9.141 6.278-4.291 12.16-10.075 16.751-16.753 9.407-13.332 12.836-29.96 11.828-42.044-.252-6.078-1.456-11.027-2.387-14.432-.374-1.734-.928-3.017-1.246-3.907l-.502-1.365L146 48.622l-.496-1.349c-.284-.817-.684-1.892-1.225-2.913-1-2.072-2.003-4.142-2.003-4.142Z"
      fill="#003C41"
    />
    <path
      d="m135.943 112.159-1.307-.804s-.578.944-1.594 2.594c-1.22 1.504-2.716 3.854-4.933 6.331-4.222 5.134-11.03 11.035-18.795 15.339-7.734 4.391-16.374 6.905-22.938 7.891-3.278.616-6.052.737-7.999.801-1.941.103-3.05.158-3.05.158V146l3.117-.161c1.986-.064 4.82-.191 8.173-.817 6.71-1.005 15.541-3.58 23.45-8.066 7.935-4.399 14.891-10.432 19.209-15.677 2.265-2.53 3.795-4.93 5.039-6.471l1.631-2.652-.003.003Z"
      fill="#005251"
      style={{
        mixBlendMode: 'multiply'
      }}
    />
    <path
      d="M49.255 132.058c-.093.222 7.184 2.812 7.184 2.812l-1.496 4.748s-7.858-2.802-7.762-3.032l2.074-4.528ZM32.995 121.332s2.256 2.224 4.877 4.005c1.262.952 2.508 1.925 3.561 2.477 1.014.614 1.69 1.021 1.69 1.021l-2.56 4.272-1.824-1.102c-1.133-.603-2.482-1.647-3.843-2.67-2.825-1.93-5.264-4.323-5.264-4.323l3.363-3.68Z"
      fill="#005251"
    />
    <path
      d="M129.318 74.931c0 7.318-1.437 14.413-4.269 21.091a53.981 53.981 0 0 1-11.651 17.226 54.18 54.18 0 0 1-17.281 11.614c-6.7 2.826-13.82 4.256-21.159 4.256-7.338 0-14.459-1.433-21.158-4.256a54.184 54.184 0 0 1-17.28-11.614 53.982 53.982 0 0 1-11.652-17.226c-2.835-6.678-4.27-13.776-4.27-21.09 0-7.316 1.438-14.414 4.27-21.092A53.979 53.979 0 0 1 36.52 36.614 54.172 54.172 0 0 1 53.8 25.001c6.7-2.826 13.82-4.256 21.159-4.256 7.338 0 14.459 1.433 21.158 4.256a54.166 54.166 0 0 1 17.281 11.613 53.977 53.977 0 0 1 11.651 17.226c2.835 6.678 4.269 13.776 4.269 21.091Zm2.652 0c0-31.388-25.526-56.83-57.011-56.83-31.486 0-57.013 25.445-57.013 56.83 0 31.386 25.527 56.831 57.013 56.831 31.485 0 57.011-25.443 57.011-56.83Z"
      fill="url(#fast-response_svg__b)"
    />
    <path
      d="M70.423 12.489s-.5.02-1.442.063c-.931.143-2.292.349-4.024.613-3.484.437-8.33 1.782-13.936 3.924-5.498 2.4-11.646 5.916-17.29 10.891-5.654 4.941-10.743 11.3-14.392 18.416-3.681 7.1-5.88 14.9-6.652 22.357-.726 7.497.075 14.54 1.353 20.37.371 1.45.73 2.841 1.069 4.163.398 1.303.91 2.498 1.31 3.648.74 2.324 1.745 4.201 2.5 5.777 1.401 3.212 2.751 4.756 2.655 4.806l-4.264 2.588-.907-1.486c-.631-.915-1.318-2.339-2.13-4.058-.851-1.697-1.824-3.688-2.556-5.948a5384.62 5384.62 0 0 1-1.265-3.429l-.942-3.653c-.742-2.427-1.069-4.939-1.419-7.286-.47-2.332-.44-4.56-.565-6.453-.138-1.896-.17-3.477-.053-4.584.056-1.108.09-1.74.09-1.74L0 71.08l.06-1.213c.08-.79.017-1.967.263-3.429.435-2.934.966-7.145 2.411-12.034.528-2.495 1.724-5.004 2.655-7.764 1.116-2.686 2.482-5.454 3.97-8.253 3.161-5.518 7.166-11.046 11.91-15.91 9.492-9.773 21.787-16.388 31.52-19.354 2.47-.634 4.732-1.219 6.74-1.737 2.023-.428 3.826-.618 5.29-.867C67.75-.008 69.456 0 69.456 0l.968 12.489Z"
      fill="#005251"
    />
    <defs>
      <linearGradient
        id="fast-response_svg__a"
        x1={51.191}
        y1={119.135}
        x2={51.191}
        y2={142.727}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#CAD688" />
        <stop offset={0.69} stopColor="#F68C24" />
        <stop offset={1} stopColor="#F26436" />
      </linearGradient>
      <linearGradient
        id="fast-response_svg__b"
        x1={74.959}
        y1={18.101}
        x2={74.959}
        y2={131.762}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#CAD688" />
        <stop offset={0.69} stopColor="#F68C24" />
        <stop offset={1} stopColor="#F26436" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgFastResponse;
