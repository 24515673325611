import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Download } from 'assets/svgs';

function Button({ children, onClick, color, download }) {
  return (
    <Container
      onClick={onClick}
      color={color}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.025 }}>
      {children}
      {download && <Download />}
    </Container>
  );
}

const Container = styled(motion.button)`
  align-items: center;
  background: ${({ color }) => color};
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  text-transform: uppercase;
  width: max-content;
  svg {
    height: 1rem;
    width: auto;
  }
`;

export default Button;
