import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { motion } from 'framer-motion';
import { ArrowRight } from 'assets/svgs';
import { AnchorLink as Anchor } from 'gatsby-plugin-anchor-links';

function DropdownNavigation({ dropdownOpen, setDropdownOpen, location }) {
  const dropdownOpenClose = {
    hidden: { y: '100%', transition: { duration: 0.5 } },
    visible: { y: '0', transition: { duration: 0.5 } }
  };

  return (
    <Container
      variants={dropdownOpenClose}
      initial="hidden"
      exit="hidden"
      animate={dropdownOpen ? 'visible' : 'hidden'}>
      {location.pathname.includes('hcp') ? (
        <Links>
          <AnchorLink to="/hcp#indications" onAnchorLinkClick={() => setDropdownOpen(false)}>
            Indications
          </AnchorLink>
          <AnchorLink to="/hcp#treatment" onAnchorLinkClick={() => setDropdownOpen(false)}>
            Treatment and Dosing
          </AnchorLink>
          <AnchorLink to="/hcp#clinical-data" onAnchorLinkClick={() => setDropdownOpen(false)}>
            Clinical Data
          </AnchorLink>
          <AnchorLink to="/hcp#role" onAnchorLinkClick={() => setDropdownOpen(false)}>
            Role of Vitamin D
          </AnchorLink>
          <AnchorLink
            to="/hcp#supporting-materials"
            onAnchorLinkClick={() => setDropdownOpen(false)}>
            Support Tools
          </AnchorLink>
        </Links>
      ) : (
        <Links>
          <AnchorLink to="/patient#what-is-dnord" onAnchorLinkClick={() => setDropdownOpen(false)}>
            What is Dnord?
          </AnchorLink>
          <AnchorLink to="/patient#how-to-take" onAnchorLinkClick={() => setDropdownOpen(false)}>
            How to take Dnord?
          </AnchorLink>
          <AnchorLink to="/patient#reminder" onAnchorLinkClick={() => setDropdownOpen(false)}>
            Reminder
          </AnchorLink>
          <AnchorLink to="/patient#faq" onAnchorLinkClick={() => setDropdownOpen(false)}>
            FAQ’s
          </AnchorLink>
          <AnchorLink
            to="/patient#supporting-materials"
            onAnchorLinkClick={() => setDropdownOpen(false)}>
            Further Information
          </AnchorLink>
        </Links>
      )}
      <PageChangeSlide
        type="button"
        invert={location.pathname.includes('patient')}
        onClick={() => {
          navigate(location.pathname.includes('patient') ? '/hcp' : '/patient');
          setDropdownOpen(false);
        }}>
        <div>
          <p>{location.pathname.includes('patient') ? 'Go To HCP Page' : 'Go To Patient Page'}</p>
          <ArrowRight width={14} height={10} />
        </div>
      </PageChangeSlide>
    </Container>
  );
}

const Container = styled(motion.div)`
  align-items: center;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: auto;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const Links = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem;
  position: relative;
  scroll-margin: 0;
  top: 0;
  &::-webkit-scrollbar {
    background: rgba(217, 217, 217, 0.2);
    border-radius: 0.625rem;
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(217, 217, 217, 0.2);
    border-radius: 0.625rem;
  }
`;

const AnchorLink = styled(Anchor)`
  color: var(--dnord-default-font-color);
  cursor: pointer;
  font-size: 1.75rem;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
`;

const PageChangeSlide = styled(motion.button)`
  align-items: center;
  border-radius: 30px;
  bottom: -30px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 60px;
  justify-content: center;
  left: 50%;
  line-height: 1;
  position: absolute;
  text-transform: uppercase;
  text-wrap: nowrap;
  transform: translate(-50%, 0);
  width: 280px;
  z-index: 100;
  div {
    background: ${({ invert }) => (invert ? 'var(--dnord-teritary)' : 'var(--dnord-secondary)')};
    border-radius: 30px;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 0.5rem 2.5rem 2.25rem 3.5rem;
    position: absolute;
    transition: all 0.5s ease-in-out;
    width: 280px;
    svg {
      height: 10px;
      margin-left: 0.5rem;
      width: 14px;
    }
  }
`;

export default DropdownNavigation;
