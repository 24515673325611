import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Dnord, ClinicalDataMainGraphic } from 'assets/svgs';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function ClinicalData() {
  const { chart } = useStaticQuery(graphql`
    query {
      chart: file(relativePath: { eq: "assets/images/postmenopausal-study-chart.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  const [toggleState, setToggleState] = useState(false);

  return (
    <InnerContainer
      id="clinical-data"
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}>
      <Title>Clinical Data</Title>
      <Statement>
        <h4>
          <Dnord /> avoids liver metabolism
          <sup>
            <small>1</small>
          </sup>
        </h4>
      </Statement>
      <ClinicalDataMainGraphic />
      <Statement style={{ margin: '3rem 0' }}>
        <h4>
          <Dnord /> is <strong>faster</strong> and more effective
          <br />
          than{' '}
          <strong>
            Vitamin D
            <sub>
              <small>3</small>
            </sub>
          </strong>{' '}
          at increasing serum
          <br />
          25(OH)D concentration
          <sup>
            <small>2,3</small>
          </sup>
        </h4>
      </Statement>
      <ProductStudy>
        <GatsbyImage image={getImage(chart)} alt="Study in Postmenopausal Women" />
        <Information>
          <Tabs>
            <Tab
              type="button"
              selected={!toggleState}
              onClick={() => setToggleState(false)}
              whileHover={{ scale: 1.025 }}>
              After 1 Month
            </Tab>
            <div />
            <Tab
              type="button"
              selected={toggleState}
              onClick={() => setToggleState(true)}
              whileHover={{ scale: 1.025 }}>
              After 4 Month
            </Tab>
          </Tabs>
          <AnimatePresence>
            {!toggleState ? (
              <>
                <Line>
                  <img src="/bullet.png" alt="bullet-point" />
                  <p>
                    <strong>59%</strong> of calcifediol patients (n=200) reached 25(OH)D levels{' '}
                    <span>&#x3e;20ng/ml</span> vs. <strong>34%</strong> of cholecalciferol patients
                    (n=98)
                    <sup>
                      <small>3</small>
                    </sup>
                  </p>
                </Line>
                <Line>
                  <img src="/bullet.png" alt="bullet-point" />
                  <p>
                    <strong>13.5%</strong> of calcifediol patients (n=200) reached 25(OH)D levels{' '}
                    <span>&#x3e;30ng/ml</span> vs. <strong>0%</strong> of cholecalciferol patients
                    (n=98)
                    <sup>
                      <small>3</small>
                    </sup>
                  </p>
                </Line>
              </>
            ) : (
              <Lines>
                <Line>
                  <img src="/bullet.png" alt="bullet-point" />
                  <p>
                    <strong>81%</strong> of calcifediol patients (n=200) reached 25(OH)D levels{' '}
                    <span>&#x3e;20ng/ml</span> vs. <strong>72.4%</strong> of cholecalciferol
                    patients (n=98)
                    <sup>
                      <small>3</small>
                    </sup>
                  </p>
                </Line>
                <Line>
                  <img src="/bullet.png" alt="bullet-point" />
                  <p>
                    <strong>35%</strong> of calcifediol patients (n=200) reached 25(OH)D levels{' '}
                    <span>&#x3e;30ng/ml</span> vs. <strong>8.2%</strong> of cholecalciferol patients
                    (n=98)
                    <sup>
                      <small>3</small>
                    </sup>
                  </p>
                </Line>
              </Lines>
            )}
          </AnimatePresence>
        </Information>
      </ProductStudy>
      <References>
        1. Charoenngam N and Holick M F: Nutrients 2020, 12, 2097; doi:10.3390/ nu12072097 2.
        Perez-Castrillón et al, Journal of Bone and Mineral Research, Vol. 38, No. 4, April 2023, pp
        471-479 <br />
        3. Perez-Castrillón et al, Journal of Bone and Mineral Research, October 2021, Vol 36, No
        10, pp 1967-1978
      </References>
    </InnerContainer>
  );
}

const InnerContainer = styled(motion.section)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  justify-content: center;
  margin: 2rem 0;
  padding: 0 1.25rem;
  @media screen and (min-width: 64rem) {
    gap: 3rem;
    grid-column: 2/12;
    margin: 0 0 6rem 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const Title = styled.h3`
  color: var(--dnord-teritary);
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  width: 100%;
`;

const Statement = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  width: 100%;
  h4 {
    font-size: 2rem;
    font-style: italic;
    font-weight: 300;
  }
  svg {
    width: 125px;
  }
  sup {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 64rem) {
    svg {
      width: 194px;
    }
    h4 {
      font-size: 3rem;
    }
  }
`;

const References = styled.span`
  color: #6d6e71;
  font-family: 'source-sans-pro', sans-serif;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  word-break: break-all;
`;

const ProductStudy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .gatsby-image-wrapper,
  img {
    object-fit: contain !important;
  }
  @media screen and (min-width: 64rem) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Tabs = styled.div`
  border-bottom-width: 4px;
  display: grid;
  grid-template-columns: auto 2px auto;
  padding: 1rem 0;
  position: relative;
  div {
    background: #bcbec0;
    height: 42px;
    width: 100%;
  }
  &:before {
    background: linear-gradient(90deg, #00544b 0%, #c9d688 43%, #ff8c1a 69%, #f46437 100%);
    bottom: 0;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;

const Tab = styled(motion.button)`
  color: ${({ selected }) => (selected ? 'var(--dnord-teritary)' : '#BCBEC0')};
  font-size: 1.5rem;
  text-transform: uppercase;
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  gap: 1.75rem;
  span {
    color: var(--dnord-secondary);
    font-weight: 700;
  }
`;

const Lines = styled(motion.div).attrs({
  initial: { opacity: 0, x: 20 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5 }
})`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`;

const Line = styled(motion.div).attrs({
  initial: { opacity: 0, x: 20 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5 }
})`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 0.5rem;
  img {
    height: 1rem;
    margin: 0.5rem 0;
    width: 1rem;
  }
`;

export default ClinicalData;
