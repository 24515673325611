import * as React from 'react';

function SvgMenuIcon(props) {
  return (
    <svg viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 1h18M3 7h12M0 13h18" stroke="#fff" strokeWidth={2} />
    </svg>
  );
}
export default SvgMenuIcon;
