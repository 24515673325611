import * as React from 'react';
const SvgDIcon = (props) => (
  <svg viewBox="0 0 140 113" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity={0.7} fill="#fff">
      <path d="M106.391.082s-56.9.008-64.494.013c-.515 0-.987.295-1.214.756-8.871 17.93-3.088 32.838 5.635 56.03a61.836 61.836 0 0 1 3.525 14.335c.02.176.264.184.3.01l9.372-44.163h40.836c7.626 0 12.713 1.744 9.852 15.236l-6.038 28.09c-2.86 13.49-9.057 15.078-16.206 15.078H51.155c-.69 0-1.261.518-1.348 1.202-1.42 11.256-5.479 19.748-7.521 23.444a1.358 1.358 0 0 0 1.194 2.016h45.432c25.264 0 37.5-14.443 43.22-41.422l6.037-28.568c5.723-27.14-6.513-42.057-31.778-42.057ZM6.891 43.387h5.99c4.624 0 9.074 1.283 13.38 2.965.791.308 1.705.37 2.649.085a3.857 3.857 0 0 0 2.542-2.509 3.963 3.963 0 0 0-3.756-5.205c-.58 0-1.133.127-1.63.351-4.978 2.261-10.548 2.884-16.016 2.884H6.862a.715.715 0 0 0-.714.735c.01.392.353.693.744.693" />
      <path d="M39.572 72.46a3.853 3.853 0 0 0 2.54-2.51 3.962 3.962 0 0 0-3.756-5.204c-.58 0-1.132.127-1.629.351-4.976 2.26-10.547 2.884-16.014 2.884h-3.19a.714.714 0 0 0-.715.735c.011.39.353.693.744.693h5.99c4.624 0 9.075 1.283 13.38 2.965a4.03 4.03 0 0 0 2.65.085M12.326 29.456h1.92c3.661 0 7.182 1.018 10.62 2.273.577.211 1.231.26 1.908.091a3.157 3.157 0 0 0 2.257-2.23 3.19 3.19 0 0 0-3.074-4.03c-.504 0-.98.118-1.404.327-3.396 1.672-7.264 2.14-11.051 2.14h-1.207a.715.715 0 0 0-.714.735c.01.393.351.694.744.694M43.074 83.463a3.188 3.188 0 0 0-4.478-3.702c-3.396 1.672-7.263 2.14-11.052 2.14h-1.207a.715.715 0 0 0-.714.738c.01.39.353.691.744.691h1.92c3.662 0 7.182 1.019 10.622 2.274a3.23 3.23 0 0 0 1.906.091 3.158 3.158 0 0 0 2.258-2.23M18.533 15.55h2.75c1.952 0 3.797.653 5.57 1.476.368.171.788.25 1.228.214a2.427 2.427 0 0 0 2.199-2.25 2.417 2.417 0 0 0-3.282-2.407c-2.388.921-4.882 1.538-7.44 1.538h-1.055a.715.715 0 0 0-.715.735c.011.393.352.693.744.693M38.094 94.15c-.307 0-.601.057-.873.161-2.386.923-4.878 1.538-7.437 1.538H28.73a.717.717 0 0 0-.717.738c.014.39.353.692.746.692h2.749c1.953 0 3.798.653 5.57 1.476.368.171.789.253 1.229.214a2.425 2.425 0 0 0 2.198-2.25 2.416 2.416 0 0 0-2.412-2.568M25.989 2.444h2.41c1.39 0 2.71.423 4.03.852a1.653 1.653 0 0 0 2.148-1.378A1.649 1.649 0 0 0 32.323.201c-1.758.709-3.705.814-5.6.814h-.765a.715.715 0 0 0-.714.735c.01.392.351.693.744.693M32.939 107.973c-.218 0-.426.043-.616.119-1.758.712-3.705.814-5.6.814h-.765a.717.717 0 0 0-.716.738c.013.39.353.691.746.691h2.408c1.39 0 2.712.423 4.032.852a1.65 1.65 0 0 0 2.148-1.378 1.649 1.649 0 0 0-1.64-1.835M32.956 60.402c2.964 0 5.3-2.746 4.592-5.826-.397-1.724-1.814-3.115-3.545-3.489a4.775 4.775 0 0 0-2.822.232c-5.109 2.079-10.407 3.655-15.924 3.655H1.455c-.39 0-.732.3-.744.693a.714.714 0 0 0 .715.735H14.62c5.57 0 11.218.886 16.152 3.464a4.684 4.684 0 0 0 2.182.535" />
    </g>
  </svg>
);
export default SvgDIcon;
