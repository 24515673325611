import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import {
  DnordCalcifediolLogoSvg,
  NordicPharmaLogoSvg,
  Email,
  Mobile,
  Web,
  Location,
  CenterRings
} from 'assets/svgs';
import { AnchorLink as Anchor } from 'gatsby-plugin-anchor-links';

function Footer() {
  const { windowWidth } = useWindowSize();
  return (
    <>
      <Sitemap>
        <h6>Sitemap</h6>
        <div>
          <Links>
            <strong>HCP Info</strong>
            <AnchorLink to="/hcp#indications">Indications</AnchorLink>
            <AnchorLink to="/hcp#treatment">Treatment and Dosing</AnchorLink>
            <AnchorLink to="/hcp#clinical-data">Clinical Data</AnchorLink>
            <AnchorLink to="/hcp#role">Role of Vitamin D</AnchorLink>
            <AnchorLink to="/hcp#supporting-materials">Support Tools</AnchorLink>
          </Links>
          <Links>
            <strong>Patient Info</strong>
            <AnchorLink to="/patient#what-is-dnord">What is Dnord?</AnchorLink>
            <AnchorLink to="/patient#how-to-take">How to take Dnord?</AnchorLink>
            <AnchorLink to="/patient#reminder">Reminder</AnchorLink>
            <AnchorLink to="/patient#faq">FAQ’s</AnchorLink>
            <AnchorLink to="/patient#supporting-materials">Further Information</AnchorLink>
          </Links>
        </div>
        {windowWidth >= 1024 && (
          <p>
            Please refer to the Summary of Product Characteristics (SmPC) for further information.
            <br />
            The SmPC is available at{' '}
            <strong>
              <a href="https://www.hpra.ie/" target="_blank" rel="noreferrer">
                www.hpra.ie
              </a>
            </strong>{' '}
            or from Nordic Pharma Ireland on <strong>+353(0)1 400 4141</strong>
          </p>
        )}
      </Sitemap>
      <ContactInformation>
        <h6>Contact Us</h6>
        <div>
          <div>
            <Email width="12" />
            <a href="mailto:info@nordicpharma.ie">info@nordicpharma.ie</a>
          </div>
          <div>
            <Mobile width="8" />
            <a href="tel:+35314004141">+353(0)1 400 4141</a>
          </div>
          <div>
            <Web width="14" />
            <a href="https://www.nordicpharma.com/" target="_blank" rel="noreferrer">
              Nordic Pharma
            </a>
          </div>
          <div>
            <Location width="10" />
            <p>
              c/o Allphar Services Ltd,
              <br />
              4045 Kingswood Road,
              <br />
              Citywest Business Park,
              <br />
              Co. Dublin, Ireland
            </p>
          </div>
        </div>
      </ContactInformation>
      {windowWidth <= 768 && (
        <EndParagraph>
          Please refer to the Summary of Product Characteristics (SmPC) for further information.
          <br />
          The SmPC is available at{' '}
          <strong>
            <a
              href="https://www.hpra.ie/"
              target="_blank"
              rel="noreferrer"
              style={{ fontWeight: '700' }}>
              www.hpra.ie
            </a>
          </strong>{' '}
          or from Nordic Pharma Ireland on <strong>+353(0)1 400 4141</strong>
        </EndParagraph>
      )}
      <InnerContainer>
        <div>
          <DnordCalcifediolLogoSvg width="149" />
          <NordicPharmaLogoSvg width="120" />
        </div>
        <div>
          <p>
            © 2024 Nordic Pharma. All rights reserved. Date of Preparation: March 2024. Item Code:
            IE-DNO-2300005.
          </p>
          <div>
            <a href="/privacy-statement.pdf">Privacy and Cookie Policy</a>
          </div>
        </div>
        <SVGContainer>
          <CenterRings />
        </SVGContainer>
      </InnerContainer>
    </>
  );
}

const InnerContainer = styled.footer`
  align-items: center;
  color: #6d6e71;
  display: flex;
  flex-direction: column;
  font-family: 'source-sans-pro', sans-serif;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  gap: 1rem;
  grid-column: 1/7;
  padding: 0 1.25rem;
  position: relative;
  h6 {
    font-size: 0.875rem;
    font-style: italic;
  }
  p,
  a {
    color: #6d6e71;
  }
  > div {
    justify-content: space-between;
    width: 100%;
    &:nth-of-type(2) {
      flex-direction: column;
    }
  }
  div {
    align-items: center;
    display: flex;
    gap: 1.5rem;
  }
  a {
    font-size: 0.875rem;
  }
  @media screen and (min-width: 64rem) {
    grid-column: 2/12;
    margin: 2rem 0 2.5rem 0;
    div {
      &:nth-of-type(2) {
        flex-direction: row;
      }
    }
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const ContactInformation = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'source-sans-pro', sans-serif;
  font-style: italic;
  gap: 1.125rem;
  grid-column: 1/7;
  padding: 1.25rem;
  h6 {
    font-family: Kallisto, sans-serif;
    font-size: 0.875rem;
    font-style: italic;
  }
  a,
  p {
    color: #6d6e71;
    text-decoration: none;
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    div {
      align-items: start;
      display: grid;
      gap: 0.75rem;
      grid-template-columns: 1rem auto;
      svg {
        margin: 0.4rem auto;
      }
    }
  }
  @media screen and (min-width: 48rem) {
    grid-column: 5/7;
  }
  @media screen and (min-width: 64rem) {
    grid-column: 10 / 12;
    padding: 3rem 0 0 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 9/11;
  }
`;

const Sitemap = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'source-sans-pro', sans-serif;
  font-style: italic;
  gap: 1.125rem;
  grid-column: 1/7;
  padding: 1.25rem;
  h6 {
    font-family: Kallisto, sans-serif;
    font-size: 0.875rem;
    font-style: italic;
  }
  a {
    color: #6d6e71;
    font-family: 'source-sans-pro', sans-serif;
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
  }
  p,
  a {
    color: #6d6e71;
  }
  p {
    margin-top: 1.5rem;
  }
  > div {
    display: flex;
    gap: 6rem;
  }
  @media screen and (min-width: 48rem) {
    grid-column: 1/5;
  }
  @media screen and (min-width: 64rem) {
    grid-column: 2 / 8;
    padding: 3rem 0 0 1.25rem;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/7;
  }
`;

const Links = styled.div`
  color: #6d6e71;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 0.25rem;
  strong {
    font-weight: 700;
  }
  a {
    text-decoration: none;
  }
`;

const EndParagraph = styled.p`
  color: #6d6e71;
  font-family: 'source-sans-pro', sans-serif;
  grid-column: 1/7;
  padding: 0 1.25rem 2rem 1.25rem;
  a {
    color: #6d6e71;
    font-size: 1rem;
  }
`;

const SVGContainer = styled.div`
  bottom: -3rem;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  z-index: 0;
  svg {
    width: 100%;
  }
  @media screen and (min-width: 64rem) {
    width: 50% !important;
  }
`;

const AnchorLink = styled(Anchor)``;

export default Footer;
