import * as React from 'react';

function SvgRingsCircle(props) {
  return (
    <svg viewBox="0 0 270 507" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity={0.5} fill="#00544B">
        <path d="M-15.88 257.91c0-20.45 16.64-37.09 37.09-37.09 20.45 0 37.09 16.64 37.09 37.09 0 20.45-16.64 37.09-37.09 37.09-20.45 0-37.09-16.64-37.09-37.09Zm.31 0c0 20.28 16.5 36.78 36.78 36.78s36.78-16.5 36.78-36.78-16.5-36.78-36.78-36.78-36.78 16.5-36.78 36.78Z" />
        <path d="M-35.07 257.91c0-31.04 25.25-56.29 56.29-56.29s56.28 25.25 56.28 56.29-25.25 56.28-56.28 56.28c-31.04 0-56.29-25.25-56.29-56.28Zm.31 0c0 30.86 25.11 55.97 55.97 55.97s55.97-25.11 55.97-55.97-25.11-55.97-55.97-55.97-55.97 25.11-55.97 55.97Z" />
        <path d="M-54.26 257.91c0-41.62 33.86-75.48 75.48-75.48 41.62 0 75.48 33.86 75.48 75.48 0 41.62-33.86 75.48-75.48 75.48-41.62 0-75.48-33.86-75.48-75.48Zm.31 0c0 41.45 33.72 75.17 75.17 75.17s75.17-33.72 75.17-75.17-33.72-75.17-75.17-75.17-75.17 33.72-75.17 75.17Z" />
        <path d="M-45.73 324.85c-17.88-17.88-27.73-41.66-27.73-66.94 0-25.29 9.85-49.06 27.73-66.94 17.88-17.88 41.65-27.73 66.94-27.73s49.06 9.85 66.94 27.73c17.88 17.88 27.73 41.65 27.73 66.94s-9.85 49.06-27.73 66.94c-17.88 17.88-41.65 27.73-66.94 27.73s-49.06-9.85-66.94-27.73Zm-27.42-66.94c0 52.03 42.33 94.36 94.36 94.36s94.36-42.33 94.36-94.36-42.33-94.36-94.36-94.36-94.36 42.33-94.36 94.36Z" />
        <path d="M-59.3 338.43c-21.51-21.51-33.35-50.1-33.35-80.51 0-30.41 11.84-59.01 33.35-80.51 21.51-21.51 50.1-33.35 80.51-33.35 30.41 0 59.01 11.84 80.51 33.35 21.5 21.51 33.35 50.1 33.35 80.51 0 30.41-11.84 59.01-33.35 80.51-21.51 21.51-50.1 33.35-80.51 33.35-30.41 0-59.01-11.84-80.51-33.35Zm-33.04-80.51c0 62.61 50.94 113.55 113.55 113.55s113.55-50.94 113.55-113.55S83.82 144.37 21.21 144.37-92.34 195.31-92.34 257.92Z" />
        <path d="M-72.87 352c-25.13-25.13-38.97-58.54-38.97-94.09 0-35.55 13.84-68.95 38.97-94.09 25.13-25.13 58.54-38.97 94.09-38.97 35.54 0 68.95 13.84 94.09 38.97 25.13 25.13 38.97 58.54 38.97 94.09 0 35.54-13.84 68.95-38.97 94.09-25.13 25.13-58.54 38.97-94.09 38.97-35.54 0-68.95-13.84-94.09-38.97Zm-38.66-94.09c0 73.2 59.55 132.74 132.74 132.74 73.2 0 132.74-59.55 132.74-132.74 0-73.2-59.55-132.74-132.74-132.74-73.2 0-132.74 59.55-132.74 132.74Z" />
        <path d="M-38.05 398.2c-18.13-7.67-34.41-18.65-48.39-32.63-13.98-13.98-24.96-30.26-32.63-48.39-7.94-18.77-11.96-38.71-11.96-59.26 0-20.55 4.03-40.49 11.96-59.26 7.67-18.13 18.65-34.41 32.63-48.39 13.98-13.98 30.26-24.96 48.39-32.63 18.77-7.94 38.71-11.96 59.26-11.96 20.55 0 40.49 4.03 59.26 11.96 18.13 7.67 34.41 18.65 48.39 32.63 13.98 13.98 24.96 30.26 32.63 48.39 7.94 18.77 11.96 38.71 11.96 59.26 0 20.55-4.03 40.49-11.96 59.26-7.67 18.13-18.65 34.41-32.63 48.39-13.98 13.98-30.26 24.96-48.39 32.63-18.77 7.94-38.71 11.96-59.26 11.96-20.55 0-40.49-4.03-59.26-11.96Zm-92.68-140.29c0 83.78 68.16 151.94 151.94 151.94s151.94-68.16 151.94-151.94-68.16-151.94-151.94-151.94-151.94 68.16-151.94 151.94Z" />
        <path d="M-45.52 415.88c-20.42-8.63-38.75-21-54.5-36.74-15.75-15.75-28.11-34.08-36.74-54.5-8.94-21.14-13.47-43.59-13.47-66.73s4.53-45.6 13.47-66.73c8.63-20.42 21-38.75 36.74-54.5 15.75-15.75 34.08-28.11 54.5-36.74C-24.38 91-1.93 86.47 21.21 86.47S66.81 91 87.94 99.94c20.42 8.63 38.75 21 54.5 36.74 15.75 15.75 28.11 34.08 36.74 54.5 8.94 21.14 13.47 43.59 13.47 66.73s-4.53 45.6-13.47 66.73c-8.63 20.42-21 38.75-36.74 54.5-15.75 15.75-34.08 28.11-54.5 36.74-21.14 8.94-43.59 13.47-66.73 13.47s-45.6-4.53-66.73-13.47Zm-104.4-157.97c0 94.36 76.77 171.13 171.13 171.13s171.13-76.77 171.13-171.13S115.57 86.78 21.21 86.78s-171.13 76.77-171.13 171.13Z" />
        <path d="M-52.99 433.57c-22.7-9.6-43.09-23.35-60.6-40.85-17.51-17.51-31.25-37.9-40.85-60.6-9.94-23.5-14.98-48.47-14.98-74.2 0-25.74 5.04-50.7 14.98-74.2 9.6-22.7 23.35-43.09 40.85-60.6 17.51-17.51 37.9-31.25 60.6-40.86 23.5-9.94 48.47-14.98 74.2-14.98 25.74 0 50.7 5.04 74.2 14.98 22.7 9.6 43.09 23.35 60.6 40.86 17.51 17.51 31.25 37.9 40.85 60.6 9.94 23.5 14.98 48.47 14.98 74.2 0 25.74-5.04 50.7-14.98 74.2-9.6 22.7-23.35 43.09-40.85 60.6-17.51 17.51-37.9 31.25-60.6 40.85-23.5 9.94-48.47 14.98-74.2 14.98-25.74 0-50.7-5.04-74.2-14.98Zm-116.12-175.66c0 104.94 85.38 190.32 190.32 190.32s190.32-85.38 190.32-190.32S126.16 67.59 21.22 67.59s-190.33 85.38-190.33 190.32Z" />
        <path d="M-60.46 451.25c-24.99-10.57-47.43-25.7-66.7-44.97-19.27-19.27-34.4-41.71-44.97-66.7-10.94-25.87-16.49-53.35-16.49-81.68 0-28.33 5.55-55.81 16.49-81.68 10.57-24.99 25.7-47.43 44.97-66.7 19.27-19.27 41.71-34.4 66.7-44.97 25.87-10.94 53.35-16.49 81.68-16.49 28.33 0 55.81 5.55 81.68 16.49 24.99 10.57 47.43 25.7 66.7 44.97 19.27 19.27 34.4 41.71 44.97 66.7 10.94 25.87 16.49 53.35 16.49 81.68 0 28.33-5.55 55.81-16.49 81.68-10.57 24.99-25.7 47.43-44.97 66.7-19.27 19.27-41.71 34.4-66.7 44.97-25.87 10.94-53.35 16.49-81.68 16.49-28.33 0-55.81-5.55-81.68-16.49ZM-188.3 257.91c0 115.53 93.99 209.52 209.52 209.52 115.53 0 209.52-93.99 209.52-209.52 0-115.53-93.99-209.52-209.52-209.52-115.53 0-209.52 93.99-209.52 209.52Z" />
        <path d="M-67.93 468.94c-27.27-11.54-51.76-28.05-72.8-49.08-21.03-21.03-37.55-45.53-49.08-72.8-11.94-28.23-18-58.23-18-89.15 0-30.92 6.06-60.91 18-89.15 11.54-27.27 28.05-51.76 49.08-72.8 21.03-21.03 45.53-37.55 72.8-49.08 28.24-11.94 58.23-18 89.15-18 30.92 0 60.91 6.06 89.15 18 27.27 11.54 51.76 28.05 72.8 49.08 21.03 21.03 37.55 45.53 49.08 72.8 11.94 28.24 18 58.23 18 89.15 0 30.92-6.06 60.91-18 89.15-11.54 27.27-28.05 51.76-49.08 72.8-21.03 21.03-45.53 37.55-72.8 49.08-28.24 11.94-58.23 18-89.15 18-30.92 0-60.91-6.06-89.15-18Zm-139.56-211.03c0 126.11 102.6 228.71 228.71 228.71 126.11 0 228.71-102.6 228.71-228.71 0-126.11-102.6-228.71-228.71-228.71-126.11 0-228.71 102.6-228.71 228.71Z" />
        <path d="M-75.4 486.62c-29.56-12.5-56.1-30.4-78.9-53.19-22.8-22.8-40.69-49.34-53.19-78.9-12.95-30.6-19.51-63.11-19.51-96.62 0-33.51 6.56-66.02 19.51-96.62 12.5-29.56 30.4-56.1 53.19-78.9 22.8-22.8 49.34-40.69 78.9-53.19C-44.8 16.26-12.29 9.69 21.22 9.69c33.51 0 66.02 6.56 96.62 19.51 29.56 12.5 56.1 30.4 78.9 53.19 22.8 22.8 40.69 49.34 53.19 78.9 12.94 30.6 19.51 63.11 19.51 96.62 0 33.51-6.56 66.02-19.51 96.62-12.5 29.56-30.4 56.1-53.19 78.9-22.8 22.8-49.34 40.69-78.9 53.19-30.6 12.94-63.11 19.51-96.62 19.51-33.51 0-66.02-6.56-96.62-19.51Zm-151.29-228.71c0 136.69 111.21 247.9 247.9 247.9s247.9-111.21 247.9-247.9S157.9 10.01 21.21 10.01s-247.9 111.21-247.9 247.9Z" />
      </g>
      <path
        d="M80.97 38.61C80.97 17.32 98.29 0 119.58 0s38.61 17.32 38.61 38.61-17.32 38.61-38.61 38.61S80.97 59.9 80.97 38.61Zm1.25 0c0 20.6 16.76 37.36 37.36 37.36 20.6 0 37.36-16.76 37.36-37.36 0-20.6-16.76-37.36-37.36-37.36-20.6 0-37.36 16.76-37.36 37.36Z"
        fill="#00544B"
      />
    </svg>
  );
}
export default SvgRingsCircle;
