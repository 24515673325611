import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Markup } from 'interweave';
import { AccordianIcon } from 'assets/animated-svgs';

function Accordian({ items }) {
  const [itemOpen, setItemOpen] = useState(null);
  return (
    <Container
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}>
      {items.map((item, index) => (
        <Item
          key={index}
          type="button"
          onClick={() => {
            if (itemOpen === index) {
              setItemOpen(null);
            } else {
              setItemOpen(index);
            }
          }}
          open={itemOpen === index}>
          <Text>
            <h5>{item.question}</h5>
            <AnimatePresence initial={false}>
              {itemOpen === index && (
                <Answer
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: 'auto' },
                    collapsed: { opacity: 0, height: 0 }
                  }}
                  transition={{
                    duration: 0.6,
                    ease: [0.04, 0.62, 0.23, 0.98],
                    opacity: { duration: 0.1 }
                  }}>
                  <Markup content={item.answer} />
                  {item.reference && (
                    <>
                      <br />
                      <br />
                      <small>Reference: {item.reference}</small>
                    </>
                  )}
                </Answer>
              )}
            </AnimatePresence>
          </Text>
          <AccordianIcon open={itemOpen === index} height="22" width="22" />
        </Item>
      ))}
    </Container>
  );
}

const Container = styled(motion.section)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-column: 1/7;
  margin: 2rem 0;
  padding: 0 1.25rem;
  width: 100%;
  @media screen and (min-width: 64rem) {
    grid-column: 2/12;
    margin: 6rem 0;
    padding: 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const Item = styled(motion.button)`
  align-items: center;
  border-radius: 2px;
  box-shadow: 4px 4px 26px 2px rgba(0, 45, 38, 0.2);
  color: var(--genseq-blue);
  cursor: pointer;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 22px;
  padding: 1.625rem 1.25rem;
  width: 100%;
  h5 {
    color: ${({ open }) => (open ? 'var(--dnord-teritary)' : 'var(--dnord-primary)')};
    font-size: 1.25rem;
    padding-bottom: ${({ open }) => (open ? '1.25rem' : '0')};
    text-transform: uppercase;
    transition: all 0.2s ease-out;
  }
  svg {
    height: 22px;
    width: 22px;
  }
  @media screen and (min-width: 1024px) {
    padding: 1.625rem 3rem 1.625rem 1.25rem;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Answer = styled(motion.div)`
  color: #6d6e71;
  font-family: 'source-sans-pro', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  small {
    font-size: 14px;
  }
  li {
    margin-bottom: 1rem;
  }
`;

export default Accordian;
