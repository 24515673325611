import * as React from 'react';
const SvgCovid = (props) => (
  <svg viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M33.125 10.74V1M33.125 65v-9.74M27.538 1h11.175M27.538 65h11.175M55.837 10.377l-6.9 6.874M17.314 48.75l-6.9 6.873M51.889 6.438l7.902 7.87M6.46 51.69l7.9 7.872M65.25 33h-9.777M10.778 33H1M65.25 27.434v11.132M1 27.434v11.132M55.473 33c0 6.149-2.501 11.714-6.537 15.749a22.378 22.378 0 0 1-15.81 6.511 22.378 22.378 0 0 1-15.811-6.511c-4.036-4.035-6.537-9.6-6.537-15.75 0-6.148 2.5-11.714 6.536-15.748a22.378 22.378 0 0 1 15.811-6.512c6.173 0 11.76 2.492 15.811 6.512 4.036 4.034 6.537 9.6 6.537 15.749ZM55.837 55.623l-6.9-6.874M17.314 17.25l-6.9-6.873M59.791 51.69l-7.902 7.872M14.36 6.438l-7.9 7.87"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.365 33c0-6.86 4.135-12.745 10.057-15.306M33.125 16.305c-1.397 0-2.751.169-4.05.502M49.885 33c0 6.86-4.134 12.744-10.057 15.305M33.125 49.695c1.398 0 2.752-.169 4.05-.502M33.125 42.74c5.4 0 9.778-4.36 9.778-9.74 0-5.379-4.378-9.74-9.778-9.74s-9.777 4.361-9.777 9.74c0 5.38 4.377 9.74 9.777 9.74Z"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.125 28.826A4.18 4.18 0 0 0 28.935 33"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCovid;
