import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Dnord, ReminderBottomFrame, ReminderTopFrame } from 'assets/svgs';
import { useWindowSize } from 'hooks';

function Reminder() {
  const { qr } = useStaticQuery(graphql`
    query {
      qr: file(relativePath: { eq: "assets/images/qr-code.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  const { windowWidth } = useWindowSize();
  return (
    <InnerContainer
      id="reminder"
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}>
      <LargeText>
        <h4>Reminder to take</h4>
        <Dnord />
      </LargeText>
      <Content>
        <QRCode>
          <GatsbyImage image={getImage(qr)} alt="QR Code to schedule reminder" />
          <TopFrame>
            <ReminderTopFrame />
          </TopFrame>
          {windowWidth < 768 && (
            <BottomFrame>
              <ReminderBottomFrame />
            </BottomFrame>
          )}
        </QRCode>
        <Text>
          <p>
            TO SET A SCHEDULE TO{' '}
            <span>
              REMIND YOU TO TAKE
              <Dnord />, SCAN THE QR CODE AND SET YOUR MONTHLY REMINDER
            </span>{' '}
            DATE AND TIME ON YOUR PHONE
          </p>
          <span style={{ maxWidth: '570px' }}>
            Nordic Pharma Ireland will not collect, use or store any of your personal information or
            data with the use of our QR code and reminder system
          </span>
        </Text>
        {windowWidth >= 768 && (
          <BottomFrame>
            <ReminderBottomFrame />
          </BottomFrame>
        )}
      </Content>
    </InnerContainer>
  );
}

const InnerContainer = styled(motion.section)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  justify-content: center;
  margin: 3rem 0;
  padding: 0 1.25rem;
  @media screen and (min-width: 64rem) {
    gap: 3rem;
    grid-column: 2/12;
    padding: 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const LargeText = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  width: 100%;
  h4 {
    font-size: 2rem;
    font-style: italic;
    font-weight: 300;
  }
  svg {
    width: 125px;
  }
  sup {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 48rem) {
    svg {
      width: 194px;
    }
    h4 {
      font-size: 3rem;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  @media screen and (min-width: 48rem) {
    display: grid;
    gap: 1rem;
    grid-template-columns: 250px auto;
    padding: 0 0 1rem 1.5rem;
  }
`;

const QRCode = styled.div`
  margin: 0 auto;
  position: relative;
  width: 90%;
  @media screen and (min-width: 48rem) {
    width: 100%;
  }
`;

const Text = styled.div`
  background: rgba(255, 140, 26, 0.2);
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 1.25rem;
  p {
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 300;
    span {
      color: var(--dnord-teritary);
      font-weight: 700;
    }
    svg {
      margin-left: 0.5rem;
      width: 112px;
    }
  }
  > span {
    font-family: 'source-sans-pro', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin-top: auto;
  }
  @media screen and (min-width: 48rem) {
    padding: 1.5rem 1.25rem;
    p {
      font-size: 1.25rem;
      svg {
        width: 100px;
      }
    }
  }
  @media screen and (min-width: 64rem) {
    padding: 3rem 1.25rem;
    p {
      font-size: 1.5rem;
      svg {
        width: 112px;
      }
    }
  }
`;

const TopFrame = styled.div`
  height: auto;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  width: 90%;
`;

const BottomFrame = styled.div`
  bottom: -0.5rem;
  height: auto;
  left: -1.5rem;
  position: absolute;
  width: 400%;
  @media screen and (min-width: 48rem) {
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;
export default Reminder;
