import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Markup } from 'interweave';
import { Button, Pathology, Modal } from 'components';
import {
  DIcon,
  Cancer,
  Asthma,
  Covid,
  Bowel,
  Muscle,
  Osteo,
  Diabetes,
  TargetIconContainer
} from 'assets/svgs';
import { useWindowSize } from '../../../hooks';

function Role({ associatedPathologies }) {
  const { bubble } = useStaticQuery(graphql`
    query {
      bubble: file(relativePath: { eq: "assets/images/bubble.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  const [showPathologyDialog, setShowPathologyDialog] = useState(false);
  const [selectedPathology, setSelectedPathology] = useState(null);
  const pathologyDialogRef = useRef(null);

  const closePathologyDialog = () => {
    pathologyDialogRef.current.setAttribute('data-state', 'close');
    pathologyDialogRef.current.children[0].setAttribute('data-state', 'close');
    setShowPathologyDialog(false);
  };

  const { windowWidth } = useWindowSize();

  const SVGMapper = {
    cancer: Cancer,
    asthma: Asthma,
    covid: Covid,
    bowel: Bowel,
    muscle: Muscle,
    osteo: Osteo,
    diabetes: Diabetes
  };

  return (
    <InnerContainer
      id="role"
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}>
      <Title>Role of Vitamin D</Title>
      <Statement>
        <strong>Vitamin D</strong> deficiency may be associated <br />
        with many pathologies
        <sup>
          <small>1,2</small>
        </sup>
      </Statement>
      <InteractiveContainer>
        {windowWidth >= 800 ? (
          <Center id="parentdiv">
            <GatsbyImage image={getImage(bubble)} alt="Bubble" />
            <DIcon />
            {associatedPathologies.map((target, index) => {
              const SVG = SVGMapper[target.svg];
              return (
                <Modal
                  trigger={
                    <Target
                      key={index}
                      element={target.position}
                      role="button"
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.025 }}
                      onClick={() => {
                        setSelectedPathology(target);
                        setShowPathologyDialog(true);
                      }}>
                      <Icon>
                        <TargetIconContainer />
                        <SVG />
                      </Icon>
                      <Markup content={target.title} />
                      <Button
                        type="button"
                        onClick={() => {
                          setSelectedPathology(target);
                          setShowPathologyDialog(true);
                        }}
                        color="var(--dnord-teritary)">
                        View More
                      </Button>
                    </Target>
                  }
                  modalContent={
                    <Pathology
                      closePathologyDialog={closePathologyDialog}
                      pathology={selectedPathology}
                    />
                  }
                />
              );
            })}
          </Center>
        ) : (
          <>
            {associatedPathologies.map((target, index) => {
              const SVG = SVGMapper[target.svg];
              return (
                <Modal
                  trigger={
                    <MobileTarget
                      key={index}
                      element={target.position}
                      role="button"
                      onClick={() => {
                        setSelectedPathology(target);
                        setShowPathologyDialog(true);
                      }}>
                      <Icon>
                        <TargetIconContainer />
                        <SVG />
                      </Icon>
                      <Markup content={target.title} />
                      <Button
                        type="button"
                        onClick={() => {
                          setSelectedPathology(target);
                          setShowPathologyDialog(true);
                        }}
                        color="var(--dnord-teritary)">
                        View More
                      </Button>
                    </MobileTarget>
                  }
                  modalContent={
                    <Pathology
                      closePathologyDialog={closePathologyDialog}
                      pathology={selectedPathology}
                    />
                  }
                />
              );
            })}
          </>
        )}
      </InteractiveContainer>
      <References>
        1. Charoenngam N and Holick M F: Nutrients 2020, 12, 2097; doi:10.3390/ nu12072097 2. Report
        of the Scientific Committee of the Food Safety Authority Of Ireland, 2020
      </References>
    </InnerContainer>
  );
}

const InnerContainer = styled(motion.section)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  justify-content: center;
  margin: 2rem 0;
  padding: 0 1.25rem;
  @media screen and (min-width: 64rem) {
    gap: 3rem;
    grid-column: 2/12;
    margin: 2rem 0 0 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const Title = styled.h3`
  color: var(--dnord-teritary);
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
`;

const Statement = styled.h4`
  font-size: 2rem;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  width: 100%;
  sup {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 64rem) {
    font-size: 3rem;
  }
`;

const InteractiveContainer = styled.div`
  align-items: center;
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  overflow: hidden;
  padding: 2rem 1.25rem;
  width: 100%;
  > div {
    &:last-of-type {
      grid-column: span 2;
    }
  }
  @media screen and (min-width: 32rem) {
    grid-template-columns: 1fr 1fr 1fr;
    > div {
      &:last-of-type {
        grid-column: span 3;
      }
    }
  }
  @media screen and (min-width: 51.25rem) {
    gap: 1rem;
    display: flex;
  }
`;

const Center = styled.div`
  height: 200px;
  margin: 12rem 0 28rem 0;
  position: relative;
  width: 200px;
  > svg {
    height: auto;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 112px;
  }
  @media screen and (min-width: 64rem) {
    width: 260px;
    height: 260px;
    margin: 12rem 0 22rem 0;
  }
`;

const Target = styled(motion.div)`
  cursor: pointer;
  display: grid;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  gap: 1rem;
  grid-template-rows: 146px auto 40px;
  height: 100px;
  justify-content: center;
  left: ${({ element }) =>
    element === 1
      ? '-130px'
      : element === 2
      ? '95%'
      : element === 3
      ? '340px'
      : element === 4
      ? '200px'
      : element === 5
      ? '40px'
      : element === 6
      ? '-140px'
      : element === 7
      ? '-280px'
      : '0'};
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: ${({ element }) =>
    element === 1
      ? '-200px'
      : element === 2
      ? '-200px'
      : element === 4
      ? '100%'
      : element === 5
      ? '400px'
      : element === 6
      ? '100%'
      : '0'};
  white-space: nowrap;
  width: 146px;
  button {
    margin: 0 auto;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  span {
    color: var(--dnord-primary);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    button {
      opacity: 1;
    }
    span {
      color: var(--dnord-teritary);
    }
  }
  @media screen and (min-width: 64rem) {
    left: ${({ element }) =>
      element === 1
        ? '-120px'
        : element === 2
        ? '100%'
        : element === 3
        ? '460px'
        : element === 4
        ? '300px'
        : element === 5
        ? '60px'
        : element === 6
        ? '-180px'
        : element === 7
        ? '-300px'
        : '0'};
    top: ${({ element }) => element === 5 && '360px'};
  }
`;

const MobileTarget = styled.div`
  cursor: pointer;
  display: grid;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 700;
  gap: 1rem;
  grid-template-rows: 146px auto 40px;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  width: 120px;
  button {
    margin: 0 auto;
  }
  span {
    color: var(--dnord-primary);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    span {
      color: var(--dnord-teritary);
    }
  }
  @media screen and (min-width: 23rem) {
    width: 146px;
    font-size: 1rem;
  }
`;

const Icon = styled.div`
  height: 100%;
  margin: 0 auto;
  position: relative;
  svg {
    height: 100%;
    position: relative;
    width: 100%;
    &:nth-of-type(2) {
      height: 64px;
      left: 49%;
      position: absolute;
      top: 48%;
      transform: translate(-50%, -50%);
    }
  }
`;

const References = styled.span`
  color: #6d6e71;
  font-family: 'source-sans-pro', sans-serif;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  word-break: break-all;
`;

export default Role;
