import * as React from 'react';
const SvgReminderBottomFrame = (props) => (
  <svg viewBox="0 0 1061 212" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.311 30.955V186.53c0 12.968 10.612 23.471 23.714 23.471H1061"
      stroke="url(#reminder-bottom-frame_svg__a)"
      strokeWidth={4}
      strokeMiterlimit={10}
    />
    <path
      d="M22.252 14.624H0v9.955h22.252v-9.955ZM22.252.228H8.082v9.956h14.17V.228Z"
      fill="#002D26"
    />
    <defs>
      <linearGradient
        id="reminder-bottom-frame_svg__a"
        x1={13.359}
        y1={122.434}
        x2={947}
        y2={122.434}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#C9D688" />
        <stop offset={0.69} stopColor="#FF8C1A" />
        <stop offset={1} stopColor="#F46437" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgReminderBottomFrame;
