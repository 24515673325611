import * as React from 'react';
const SvgEffectiveTherapy = (props) => (
  <svg viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle
      cx={71}
      cy={75}
      r={50}
      transform="rotate(-180 71 75)"
      fill="#82BB56"
      fillOpacity={0.3}
    />
    <path
      d="M94.742 75H103m-64 0h8.258M71 98.744V107m0-64v8.256m-10.323 23.73L71 85.306l29.935-31.986M83.293 63.965c-3.03-3.373-7.413-5.491-12.293-5.491-9.116 0-16.516 7.393-16.516 16.513 0 9.12 7.4 16.512 16.516 16.512 9.116 0 16.516-7.393 16.516-16.512m9.773-9.296a27.669 27.669 0 0 1 1.582 9.296c0 15.392-12.481 27.858-27.871 27.858-15.39 0-27.871-12.452-27.871-27.845 0-15.393 12.481-27.872 27.871-27.872a27.787 27.787 0 0 1 19.613 8.068"
      stroke="#182F2D"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      style={{
        mixBlendMode: 'multiply'
      }}
      d="M26.864 30.597s-2.189 2.217-5.128 5.827c-2.953 3.617-6.152 8.985-8.656 14.666-2.318 5.768-3.762 11.841-4.298 16.477-.4 2.3-.273 4.27-.413 5.623l-.119 2.137H3.272l.122-2.305c.145-1.46.026-3.583.447-6.065.581-5.002 2.133-11.563 4.645-17.79 2.69-6.142 6.16-11.93 9.34-15.833 3.167-3.899 5.535-6.283 5.535-6.283l3.5 3.549.003-.003Z"
      fill="url(#effective-therapy_svg__a)"
    />
    <path
      d="m142.13 99.933-11.841-4.095s-.656 1.804-1.309 3.61c-.761 1.758-1.633 3.472-1.633 3.472l11.251 5.521s1.042-2.061 1.948-4.177l1.581-4.33h.003ZM127.997 124.592l-9.486-8.208s-1.287 1.427-2.577 2.851c-.606.751-1.386 1.329-1.901 1.833l-.888.806 8.422 9.301 1.063-.971c.621-.599 1.536-1.313 2.274-2.201l3.093-3.413v.002ZM105.782 142.276l-5.794-11.138s-1.817.859-4.994 2.365c-1.666.563-3.635 1.403-5.972 2.119-2.387.531-5.007 1.478-8.021 1.783-11.836 2.291-28.747.244-42.494-8.304-6.881-4.175-12.895-9.747-17.442-15.775-4.53-6.041-7.802-12.401-9.589-18.175-.93-2.864-1.576-5.572-2.075-7.97-.307-2.436-.793-4.534-.867-6.303-.183-3.533-.286-5.554-.286-5.554h-4.38s.105 2.03.285 5.58c.262 3.544 1.076 8.564 2.726 14.422 1.919 5.769 4.795 12.314 9.141 18.573 4.291 6.278 10.075 12.16 16.753 16.751 13.332 9.407 29.96 12.836 42.044 11.828 6.078-.252 11.027-1.456 14.432-2.387 1.734-.374 3.017-.928 3.907-1.246l1.365-.502L97.378 146l1.349-.496c.816-.284 1.892-.684 2.913-1.225 2.073-1 4.142-2.003 4.142-2.003Z"
      fill="#003C41"
    />
    <path
      d="m33.841 135.943.804-1.307s-.944-.578-2.594-1.594c-1.504-1.22-3.854-2.716-6.331-4.933-5.134-4.222-11.035-11.03-15.338-18.795C5.99 101.58 3.476 92.94 2.49 86.376c-.616-3.278-.737-6.052-.8-7.999-.104-1.941-.16-3.05-.16-3.05H0l.161 3.116c.064 1.987.19 4.822.817 8.174 1.005 6.71 3.58 15.541 8.066 23.45 4.399 7.935 10.432 14.891 15.677 19.209 2.53 2.265 4.93 3.795 6.471 5.039l2.652 1.631-.003-.003Z"
      fill="#005251"
      style={{
        mixBlendMode: 'multiply'
      }}
    />
    <path
      d="M13.942 49.255c-.222-.093-2.813 7.184-2.813 7.184l-4.747-1.496s2.802-7.858 3.032-7.762l4.528 2.074ZM24.668 32.995s-2.224 2.256-4.005 4.877c-.952 1.262-1.925 2.508-2.477 3.561-.614 1.014-1.02 1.69-1.02 1.69l-4.273-2.56 1.102-1.824c.603-1.133 1.647-2.482 2.67-3.843 1.93-2.825 4.323-5.264 4.323-5.264l3.68 3.363Z"
      fill="#005251"
    />
    <path
      d="M71.069 129.318c-7.318 0-14.413-1.437-21.091-4.27a53.958 53.958 0 0 1-17.226-11.65 54.165 54.165 0 0 1-11.614-17.281c-2.826-6.7-4.256-13.82-4.256-21.159 0-7.338 1.433-14.459 4.256-21.158a54.168 54.168 0 0 1 11.614-17.28 53.976 53.976 0 0 1 17.226-11.652c6.678-2.835 13.776-4.27 21.09-4.27 7.316 0 14.414 1.438 21.092 4.27a53.979 53.979 0 0 1 17.226 11.651A54.164 54.164 0 0 1 120.999 53.8c2.826 6.7 4.256 13.82 4.256 21.159 0 7.338-1.433 14.459-4.256 21.158a54.161 54.161 0 0 1-11.613 17.281 53.96 53.96 0 0 1-17.226 11.65c-6.678 2.836-13.776 4.27-21.091 4.27Zm0 2.652c31.388 0 56.83-25.526 56.83-57.011 0-31.486-25.445-57.013-56.83-57.013-31.386 0-56.83 25.527-56.83 57.012 0 31.486 25.442 57.012 56.83 57.012Z"
      fill="url(#effective-therapy_svg__b)"
    />
    <path
      d="m133.511 70.424-.063-1.443c-.143-.931-.349-2.292-.613-4.023-.437-3.485-1.782-8.33-3.923-13.937-2.401-5.498-5.917-11.645-10.892-17.29-4.941-5.653-11.299-10.743-18.416-14.392-7.1-3.681-14.9-5.88-22.357-6.651-7.497-.727-14.54.074-20.37 1.352-1.45.371-2.841.73-4.163 1.069-1.303.398-2.498.91-3.648 1.31-2.324.74-4.201 1.745-5.777 2.501-3.212 1.4-4.756 2.75-4.806 2.655l-2.588-4.265 1.486-.907c.915-.631 2.34-1.318 4.058-2.13 1.697-.85 3.688-1.824 5.948-2.556l3.429-1.265 3.653-.942c2.427-.742 4.939-1.068 7.286-1.419 2.332-.469 4.56-.44 6.453-.564 1.896-.138 3.477-.17 4.584-.053 1.108.055 1.74.09 1.74.09L74.92 0l1.213.061c.79.08 1.967.016 3.429.263 2.934.434 7.145.965 12.034 2.41 2.495.528 5.004 1.724 7.764 2.655 2.686 1.117 5.454 2.482 8.253 3.97 5.518 3.162 11.045 7.166 15.91 11.91 9.773 9.493 16.388 21.788 19.354 31.52.634 2.47 1.219 4.732 1.737 6.74.428 2.023.618 3.827.867 5.29.526 2.931.518 4.637.518 4.637l-12.489.968Z"
      fill="#005251"
    />
    <defs>
      <linearGradient
        id="effective-therapy_svg__a"
        x1={26.864}
        y1={51.191}
        x2={3.272}
        y2={51.191}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#CAD688" />
        <stop offset={0.69} stopColor="#F68C24" />
        <stop offset={1} stopColor="#F26436" />
      </linearGradient>
      <linearGradient
        id="effective-therapy_svg__b"
        x1={127.899}
        y1={74.959}
        x2={14.238}
        y2={74.959}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#CAD688" />
        <stop offset={0.69} stopColor="#F68C24" />
        <stop offset={1} stopColor="#F26436" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgEffectiveTherapy;
