import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --dnord-primary: #002D26;
    --dnord-secondary: #82BB56;
    --dnord-teritary: #F26436;
    --dnord-default-font-color: #00544B;
  }

  html,
  body {
    color: var(--dnord-primary);
    font-family: kallisto, sans-serif;
    font-size: 16px;
    position: relative;
    box-sizing: border-box;
    z-index: 0;
    height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    ${({ theme }) => css`
      overflow-y: ${theme.dropdownOpen ? 'hidden' : 'visible'};
    `}
  }

  sup, sub, small {
    line-height: 0;
  }

  ul, ol {
    margin-left: 1rem;
  }

  button {
    background: transparent;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    outline: none;
    font-family: kallisto, sans-serif;
    font-weight: bold;
    font-style: italic;
  }

  * {
    margin:0;
    padding:0;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

export default GlobalStyle;
