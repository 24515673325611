import * as React from 'react';
const SvgSimpleDosing = (props) => (
  <svg viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={75} cy={71} r={50} fill="#82BB56" fillOpacity={0.3} />
    <path
      d="M71.995 83.174h4.826M59.923 77.9h39.77m-39.77-5.274h39.77m-25.816-5.273h25.816m-26.679 22.04h27.231c2.618 0 4.755-2.063 4.755-4.6V50.6c0-2.536-2.123-4.6-4.755-4.6H57.658c-2.632 0-4.755 2.064-4.755 4.6v27.988m17.323-26.024h-9.242c-.58 0-1.061.458-1.061 1.031v7.41c0 .572.48 1.031 1.061 1.031h9.242c.58 0 1.061-.459 1.061-1.032v-7.409c0-.573-.48-1.032-1.061-1.032Zm3.666 34.35C73.892 91.93 63.107 96 57.927 96S41 91.93 41 86.914s11.719-9.085 16.913-9.085c5.194 0 15.965 4.07 15.965 9.085h.014Z"
      stroke="#182F2D"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      style={{
        mixBlendMode: 'multiply'
      }}
      d="M119.136 115.403s2.189-2.217 5.128-5.827c2.953-3.617 6.152-8.985 8.656-14.666 2.318-5.768 3.762-11.841 4.298-16.477.399-2.3.273-4.27.413-5.623l.119-2.137h4.978l-.122 2.305c-.145 1.46-.026 3.583-.447 6.065-.581 5.002-2.133 11.563-4.645 17.79-2.691 6.142-6.159 11.929-9.34 15.833-3.167 3.899-5.535 6.283-5.535 6.283l-3.501-3.549-.002.003Z"
      fill="url(#simple-dosing_svg__a)"
    />
    <path
      d="m3.87 46.067 11.841 4.095s.656-1.804 1.309-3.61c.76-1.758 1.633-3.471 1.633-3.471L7.402 37.559s-1.042 2.06-1.948 4.177l-1.581 4.33H3.87ZM18.003 21.408l9.486 8.208s1.287-1.427 2.577-2.851c.606-.75 1.386-1.329 1.901-1.833l.888-.806-8.422-9.3-1.063.97c-.621.6-1.536 1.313-2.274 2.201l-3.093 3.413v-.002ZM40.218 3.724l5.794 11.138s1.817-.859 4.994-2.365c1.666-.563 3.635-1.403 5.972-2.12C59.365 9.848 61.985 8.9 65 8.596c11.836-2.291 28.747-.244 42.494 8.304 6.881 4.174 12.895 9.746 17.443 15.775 4.528 6.041 7.801 12.4 9.588 18.175.931 2.864 1.576 5.572 2.075 7.969.307 2.437.793 4.535.867 6.304.183 3.533.286 5.553.286 5.553h4.38s-.105-2.028-.285-5.58c-.262-3.543-1.076-8.563-2.726-14.422-1.919-5.768-4.795-12.313-9.141-18.572-4.291-6.278-10.075-12.16-16.753-16.75C99.895 5.942 83.267 2.513 71.183 3.521c-6.078.252-11.027 1.456-14.432 2.387-1.734.374-3.017.928-3.907 1.246l-1.365.502L48.622 0l-1.349.496c-.817.284-1.892.684-2.913 1.225-2.072 1-4.142 2.003-4.142 2.003Z"
      fill="#003C41"
    />
    <path
      d="m112.159 10.057-.804 1.307s.944.578 2.594 1.594c1.504 1.22 3.854 2.716 6.331 4.933 5.134 4.222 11.035 11.03 15.339 18.795 4.391 7.734 6.905 16.374 7.891 22.938.616 3.278.737 6.052.801 7.999.103 1.941.158 3.05.158 3.05H146l-.161-3.117c-.064-1.986-.191-4.82-.817-8.173-1.005-6.71-3.58-15.541-8.066-23.45-4.399-7.935-10.432-14.891-15.677-19.209-2.53-2.265-4.93-3.795-6.471-5.039l-2.652-1.631.003.003Z"
      fill="#005251"
      style={{
        mixBlendMode: 'multiply'
      }}
    />
    <path
      d="M132.058 96.746c.222.092 2.812-7.185 2.812-7.185l4.748 1.496s-2.802 7.858-3.032 7.763l-4.528-2.074ZM121.332 113.006s2.224-2.257 4.005-4.878c.952-1.262 1.925-2.509 2.477-3.561.614-1.014 1.021-1.69 1.021-1.69l4.272 2.559-1.102 1.825c-.603 1.133-1.647 2.482-2.67 3.843-1.93 2.824-4.323 5.264-4.323 5.264l-3.68-3.362Z"
      fill="#005251"
    />
    <path
      d="M74.931 16.682c7.318 0 14.413 1.437 21.091 4.27a53.98 53.98 0 0 1 17.226 11.65 54.184 54.184 0 0 1 11.614 17.281c2.826 6.7 4.256 13.82 4.256 21.158 0 7.339-1.433 14.46-4.256 21.159a54.185 54.185 0 0 1-11.614 17.281 53.981 53.981 0 0 1-17.226 11.651c-6.678 2.835-13.776 4.269-21.09 4.269-7.316 0-14.414-1.437-21.092-4.269a53.977 53.977 0 0 1-17.226-11.651A54.17 54.17 0 0 1 25.002 92.2c-2.826-6.7-4.256-13.82-4.256-21.159 0-7.338 1.433-14.459 4.256-21.158a54.169 54.169 0 0 1 11.613-17.28A53.976 53.976 0 0 1 53.84 20.95c6.678-2.835 13.776-4.27 21.091-4.27Zm0-2.652c-31.388 0-56.83 25.526-56.83 57.011 0 31.486 25.445 57.012 56.83 57.012 31.386 0 56.831-25.526 56.831-57.011 0-31.486-25.443-57.013-56.83-57.013Z"
      fill="url(#simple-dosing_svg__b)"
    />
    <path
      d="M12.489 75.577s.02.5.063 1.442c.143.931.349 2.292.613 4.024.437 3.484 1.782 8.33 3.924 13.936 2.4 5.498 5.916 11.646 10.891 17.289 4.941 5.655 11.3 10.744 18.416 14.393 7.1 3.681 14.9 5.88 22.357 6.652 7.498.726 14.54-.075 20.37-1.353 1.45-.371 2.841-.729 4.163-1.069 1.303-.398 2.498-.909 3.648-1.31 2.324-.74 4.201-1.745 5.777-2.501 3.212-1.4 4.756-2.75 4.806-2.655l2.588 4.265-1.486.907c-.915.631-2.339 1.318-4.058 2.13-1.697.851-3.688 1.824-5.948 2.556-1.113.411-2.263.836-3.429 1.265l-3.653.942c-2.427.742-4.939 1.069-7.286 1.419-2.332.469-4.56.44-6.453.565-1.896.138-3.477.169-4.584.053-1.108-.056-1.74-.091-1.74-.091L71.08 146l-1.213-.061c-.79-.079-1.967-.016-3.429-.262-2.934-.435-7.145-.966-12.034-2.411-2.495-.528-5.004-1.724-7.764-2.655-2.686-1.116-5.454-2.482-8.253-3.97-5.518-3.161-11.046-7.166-15.91-11.911-9.773-9.491-16.388-21.786-19.354-31.52-.634-2.469-1.219-4.73-1.737-6.739-.428-2.023-.618-3.827-.867-5.29C-.008 78.25 0 76.544 0 76.544l12.489-.968Z"
      fill="#005251"
    />
    <defs>
      <linearGradient
        id="simple-dosing_svg__a"
        x1={119.136}
        y1={94.809}
        x2={142.728}
        y2={94.809}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#CAD688" />
        <stop offset={0.69} stopColor="#F68C24" />
        <stop offset={1} stopColor="#F26436" />
      </linearGradient>
      <linearGradient
        id="simple-dosing_svg__b"
        x1={18.101}
        y1={71.041}
        x2={131.762}
        y2={71.041}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#CAD688" />
        <stop offset={0.69} stopColor="#F68C24" />
        <stop offset={1} stopColor="#F26436" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSimpleDosing;
