import * as React from 'react';
const SvgAsthma = (props) => (
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m53.43 63.546-10.194-7.869a12.241 12.241 0 0 1-4.768-9.688V15.978c0-.463.04-.927.137-1.363.628-3.103 3.392-5.448 6.7-5.448a6.83 6.83 0 0 1 5.864 3.323A93.014 93.014 0 0 1 64.143 53.84l.3 4.35c0 3.76-3.06 6.808-6.834 6.808-1.583 0-3.018-.558-4.178-1.454v.003ZM33.002 1v13.588a21.73 21.73 0 0 1-4.622 13.386L12.496 48.278"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.002 1v13.588c0 4.847 1.628 9.56 4.62 13.386l.849 1.089M12.578 63.543C11.422 64.442 9.977 65 8.395 65 4.62 65 1.56 61.95 1.56 58.192l.3-4.35a93.015 93.015 0 0 1 12.974-41.349 6.835 6.835 0 0 1 5.864-3.323 6.83 6.83 0 0 1 6.7 5.448c.095.436.137.9.137 1.363v30.01c0 3.79-1.76 7.366-4.765 9.686l-10.189 7.869-.003-.003ZM49.311 28.328l-5.374 5.354M15.229 52.745l-6.834 6.807M9.763 51.382l-2.737 2.725M54.135 30.33 43.937 40.49M53.22 38.05l-9.283 9.246M57.609 33.682l-2.449 2.435M45.7 52.348l-2.938 2.93M61.34 36.773 47.644 50.415M55.953 48.946l-9.35 9.314M62.613 42.312l-4.717 4.698M63.568 48.169 50.444 61.24M64.183 54.363l-9.828 9.793M23.431 35.041H12.783M19.33 50.022V39.126"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgAsthma;
