import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Dnord } from 'assets/svgs';
import { Button } from 'components';

function Dosage({ image }) {
  return (
    <DoseContainer>
      <div>1</div>
      <Dnord />
      <GatsbyImage image={getImage(image)} alt="Dnord" />
    </DoseContainer>
  );
}

function TreatmentDosing() {
  const { pill } = useStaticQuery(graphql`
    query {
      pill: file(relativePath: { eq: "assets/images/pill.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <InnerContainer
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      id="treatment">
      <Title>Treatment & Dosing</Title>
      <Grid>
        <Item gridArea="first">
          <h4>
            Treatment
            <sup>
              <small>1</small>
            </sup>
          </h4>
          <Dosage image={pill} />
          <span>
            soft gel capsule,
            <br />
            once a month
          </span>
        </Item>
        <Item gridArea="second">
          <h4>
            Prevention
            <sup>
              <small>1</small>
            </sup>
          </h4>
          <Dosage image={pill} />
          <span>
            soft gel capsule,
            <br />
            once a month
          </span>
        </Item>
        <Item gridArea="third">
          {' '}
          <h4>
            Adjuvant Treatment
            <sup>
              <small>1</small>
            </sup>
          </h4>
          <Dosage image={pill} />
          <span>
            soft gel capsule,
            <br />
            once a month
          </span>
        </Item>
        <Item gridArea="fourth">
          <span>
            Higher doses may be necessary in some patients after analytical verification of the
            extent of the deficiency.
            <br />
            Maximum dose should not exceed <strong>1 capsule, once a week.</strong>
            <br />
            Once plasma levels of 25(OH)D are stabilised within the desired range, treatment should
            be discontinued, or the frequency of administration lowered.
            <br />
            <strong>Should not be administered with a daily frequency.</strong>
          </span>
        </Item>
        <Item gridArea="fifth">
          <Dnord />
          <span>must be swallowed whole</span>
        </Item>
        <Item gridArea="sixth">
          <Dnord />
          <span>can be taken with water</span>
        </Item>
        <Item gridArea="seventh">
          <Dnord />
          <span>can be taken any time of the day</span>
        </Item>
        <Item gridArea="eight">
          <div>
            <span>
              <Dnord /> is reimbursed under the GMS and Community Drugs Schemes and is available in
              packs of 10 soft capsules for €16.32
            </span>
          </div>
        </Item>
      </Grid>
      <Button
        onClick={() => window.open('/reminder-card.pdf', '_blank')}
        color="var(--dnord-teritary)"
        download>
        Download Reminder Card
      </Button>
      <References>
        1. DNORD SPC;
        https://www.hpra.ie/img/uploaded/swedocuments/Licence_PA23343-001-001_15112022130834.pdf
      </References>
    </InnerContainer>
  );
}

const InnerContainer = styled(motion.section)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  justify-content: center;
  margin: 2rem 0;
  padding: 0 1.25rem;
  position: relative;
  @media screen and (min-width: 64rem) {
    gap: 3rem;
    grid-column: 2/12;
    margin: 0 0 8rem 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const Title = styled.h3`
  color: var(--dnord-teritary);
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  gap: 2rem;
  width: 100%;
  @media screen and (min-width: 48rem) {
    font-size: 1.375rem;
  }
  @media screen and (min-width: 64rem) {
    display: grid;
    font-size: 1rem;
    gap: 1.875rem 3.125rem;
    grid-template-areas:
      'first second third'
      'fourth fourth fourth'
      'fifth sixth seventh'
      'eight eight eight';
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 180px auto auto;
  }
`;

const Item = styled.div`
  align-items: center;
  background: white;
  border-radius: 2px;
  box-shadow: 4px 4px 26px 2px rgba(0, 45, 38, 0.2);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-area: ${({ gridArea }) => gridArea};
  justify-content: center;
  padding: 2.5rem;
  h4 {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    sup {
      font-size: 0.875rem;
      padding-left: 0.125rem;
    }
  }
  svg {
    width: 98px;
  }
  span {
    color: #707070;
    font-family: 'source-sans-pro', sans-serif;
    text-align: center;
  }
`;

const DoseContainer = styled.div`
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 40px 98px 66px;
  justify-content: center;
  width: 100%;
  img {
    object-fit: contain;
  }
  > div {
    &:first-of-type {
      align-items: center;
      background-color: var(--dnord-teritary);
      border-radius: 50%;
      color: #ffffff;
      display: flex;
      font-size: 1.5rem;
      font-weight: 700;
      height: 40px;
      justify-content: center;
      width: 100%;
    }
  }
`;

const References = styled.span`
  color: #6d6e71;
  font-family: 'source-sans-pro', sans-serif;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  word-break: break-all;
`;

export default TreatmentDosing;
