import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, Navigation } from 'components';
import { AnimatePresence, useScroll } from 'framer-motion';

function Layout({ children, location }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { scrollYProgress } = useScroll();

  const theme = {
    dropdownOpen
  };
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AnimatePresence>
        {location.pathname !== '/' && (
          <Navigation
            location={location}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            scrollYProgress={scrollYProgress}
          />
        )}
        <main id="main">{children}</main>
      </AnimatePresence>
    </ThemeProvider>
  );
}

export default Layout;
