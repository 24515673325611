import React from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Markup } from 'interweave';

function Pathology({ pathology }) {
  return (
    <Container>
      <h5>{pathology.title.replaceAll('<br />', ' ')}</h5>
      <GatsbyImage image={getImage(pathology.image)} alt={pathology.imageDescription} />
      <ImageDescription>
        <Markup content={pathology.imageDescription} />
      </ImageDescription>
      <p>
        <Markup content={pathology.details} />
      </p>
    </Container>
  );
}

const Container = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow: 4px 4px 10px 2px rgba(0, 45, 38, 0.2);
  display: flex;
  flex-direction: column;
  font-family: 'source-sans-pro', sans-serif;
  gap: 2rem;
  padding: 5rem 1.25rem 2.625rem 1.25rem;
  width: 100%;
  h5 {
    font-family: Kallisto, sans-serif;
    font-size: 1.25rem;
    font-style: italic;
    text-align: center;
    text-transform: uppercase;
  }
  p {
    color: #707070;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  ul {
    ul {
      list-style-type: disc;
    }
  }
  ol {
    margin-top: 2rem;
  }
  @media screen and (min-width: 64rem) {
    padding: 2.625rem 7.5rem;
    max-width: 940px;
  }
`;

const ImageDescription = styled.div`
  color: #707070;
  font-size: 0.625rem;
`;

export default Pathology;
