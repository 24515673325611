import * as React from 'react';
const SvgMobile = (props) => (
  <svg viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 13.963V2.037C1 1.415 1.38 1 1.952 1h6.096C8.619 1 9 1.415 9 2.037v11.926C9 14.585 8.619 15 8.048 15H1.952C1.381 15 1 14.585 1 13.963ZM1 11.267h8M4.333 13.03h1.429M4.905 2.763h.285"
      stroke="#00544B"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMobile;
