import * as React from 'react';
const SvgTargetIconContainer = (props) => (
  <svg viewBox="0 0 147 146" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle
      cx={71.5}
      cy={71}
      r={50}
      transform="rotate(-90 71.5 71)"
      fill="#82BB56"
      fillOpacity={0.3}
    />
    <path
      style={{
        mixBlendMode: 'multiply'
      }}
      d="M115.903 26.864s-2.217-2.189-5.827-5.128c-3.617-2.953-8.985-6.152-14.666-8.656-5.768-2.318-11.841-3.761-16.477-4.298-2.3-.4-4.27-.272-5.623-.413l-2.137-.118V3.273l2.305.121c1.46.146 3.583.027 6.065.447 5.002.582 11.563 2.133 17.79 4.645 6.142 2.691 11.929 6.16 15.833 9.34 3.899 3.167 6.283 5.535 6.283 5.535l-3.549 3.5.003.003Z"
      fill="url(#target-icon-container_svg__a)"
    />
    <path
      d="m46.567 142.13 4.095-11.841s-1.804-.656-3.61-1.309c-1.758-.761-3.471-1.633-3.471-1.633l-5.522 11.251s2.06 1.042 4.177 1.948l4.33 1.581v.003ZM21.908 127.997l8.208-9.486s-1.427-1.287-2.851-2.577c-.75-.606-1.329-1.385-1.833-1.901l-.806-.888-9.3 8.422.97 1.063c.6.621 1.313 1.536 2.201 2.274a12098.6 12098.6 0 0 0 3.413 3.093h-.002ZM4.224 105.782l11.138-5.794s-.859-1.817-2.365-4.994c-.563-1.666-1.403-3.635-2.12-5.972-.53-2.388-1.477-5.007-1.782-8.021-2.291-11.836-.244-28.747 8.304-42.494 4.174-6.881 9.746-12.896 15.775-17.443 6.041-4.528 12.4-7.801 18.175-9.588 2.864-.93 5.572-1.576 7.969-2.075 2.437-.307 4.535-.793 6.304-.868l5.553-.285v-4.38s-2.028.105-5.58.285c-3.543.262-8.563 1.076-14.422 2.726-5.768 1.919-12.313 4.795-18.572 9.141-6.278 4.29-12.16 10.075-16.75 16.753C6.442 46.105 3.013 62.733 4.021 74.817c.252 6.078 1.456 11.027 2.387 14.432.374 1.734.928 3.016 1.246 3.907l.502 1.364L.5 97.379l.496 1.349c.284.816.684 1.892 1.225 2.913 1 2.072 2.003 4.142 2.003 4.142Z"
      fill="#003C41"
    />
    <path
      d="m10.557 33.841 1.307.804s.578-.944 1.594-2.594c1.22-1.504 2.716-3.854 4.933-6.331 4.222-5.134 11.03-11.035 18.795-15.338C44.92 5.99 53.56 3.476 60.124 2.49c3.278-.616 6.052-.737 7.999-.8 1.941-.104 3.05-.16 3.05-.16V0l-3.117.161c-1.986.064-4.821.19-8.173.817-6.71 1.005-15.541 3.58-23.45 8.066-7.935 4.399-14.891 10.432-19.209 15.677-2.265 2.53-3.795 4.93-5.039 6.471l-1.631 2.652.003-.003Z"
      fill="#005251"
      style={{
        mixBlendMode: 'multiply'
      }}
    />
    <path
      d="M97.246 13.942c.092-.222-7.185-2.813-7.185-2.813l1.496-4.747s7.858 2.802 7.763 3.032l-2.074 4.528ZM113.506 24.668s-2.257-2.223-4.878-4.005c-1.262-.952-2.509-1.925-3.561-2.477-1.014-.614-1.69-1.02-1.69-1.02l2.559-4.273s.73.442 1.825 1.102c1.133.603 2.482 1.648 3.843 2.67 2.824 1.93 5.264 4.323 5.264 4.323l-3.362 3.68Z"
      fill="#005251"
    />
    <path
      d="M17.182 71.069c0-7.318 1.437-14.413 4.27-21.091a53.976 53.976 0 0 1 11.65-17.226 54.169 54.169 0 0 1 17.281-11.614c6.7-2.826 13.82-4.256 21.158-4.256 7.339 0 14.46 1.433 21.159 4.256a54.172 54.172 0 0 1 17.281 11.614 53.96 53.96 0 0 1 11.65 17.226c2.836 6.678 4.27 13.776 4.27 21.09 0 7.316-1.437 14.414-4.269 21.092a53.98 53.98 0 0 1-11.651 17.226A54.182 54.182 0 0 1 92.7 120.999c-6.7 2.826-13.82 4.256-21.159 4.256-7.338 0-14.459-1.433-21.158-4.256a54.18 54.18 0 0 1-17.28-11.613A53.979 53.979 0 0 1 21.45 92.16c-2.835-6.678-4.27-13.776-4.27-21.091Zm-2.652 0c0 31.388 25.526 56.83 57.011 56.83 31.486 0 57.012-25.445 57.012-56.83 0-31.386-25.526-56.83-57.011-56.83-31.486 0-57.013 25.442-57.013 56.83Z"
      fill="url(#target-icon-container_svg__b)"
    />
    <path
      d="m76.077 133.511 1.442-.063c.931-.143 2.292-.349 4.024-.613 3.484-.437 8.33-1.782 13.936-3.923 5.498-2.401 11.646-5.917 17.289-10.892 5.655-4.941 10.744-11.299 14.393-18.416 3.681-7.1 5.88-14.9 6.652-22.357.726-7.498-.075-14.54-1.353-20.37-.371-1.45-.729-2.841-1.069-4.163-.398-1.303-.909-2.498-1.31-3.648-.74-2.324-1.745-4.201-2.501-5.777-1.4-3.212-2.75-4.756-2.655-4.806l4.265-2.588.907 1.486c.631.914 1.318 2.34 2.13 4.058.851 1.697 1.824 3.688 2.556 5.948.411 1.113.836 2.263 1.265 3.429l.942 3.653c.742 2.427 1.069 4.939 1.419 7.286.469 2.332.44 4.56.565 6.453.138 1.896.169 3.477.053 4.584-.056 1.108-.091 1.74-.091 1.74l7.564.389-.061 1.213c-.079.79-.016 1.967-.262 3.429-.435 2.934-.966 7.145-2.411 12.034-.528 2.495-1.724 5.004-2.655 7.764-1.116 2.686-2.482 5.454-3.97 8.253-3.161 5.518-7.166 11.045-11.911 15.91-9.491 9.773-21.786 16.388-31.52 19.354-2.469.634-4.73 1.219-6.739 1.737-2.023.428-3.827.618-5.29.867-2.931.526-4.636.518-4.636.518l-.968-12.489Z"
      fill="#005251"
    />
    <defs>
      <linearGradient
        id="target-icon-container_svg__a"
        x1={95.309}
        y1={26.864}
        x2={95.309}
        y2={3.273}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#CAD688" />
        <stop offset={0.69} stopColor="#F68C24" />
        <stop offset={1} stopColor="#F26436" />
      </linearGradient>
      <linearGradient
        id="target-icon-container_svg__b"
        x1={71.541}
        y1={127.899}
        x2={71.541}
        y2={14.238}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#00544B" />
        <stop offset={0.43} stopColor="#CAD688" />
        <stop offset={0.69} stopColor="#F68C24" />
        <stop offset={1} stopColor="#F26436" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgTargetIconContainer;
