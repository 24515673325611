import * as React from 'react';
const SvgDiabetes = (props) => (
  <svg viewBox="0 0 49 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.124 42.277C47.124 54.827 36.91 65 24.312 65S1.5 54.826 1.5 42.277 24.312 1 24.312 1s22.812 28.728 22.812 41.277ZM18.224 31.145h11.502M18.224 44.73h11.502M23.974 39.002V50.46"
      stroke="#002D26"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgDiabetes;
