import * as React from 'react';
const SvgWeb = (props) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z" stroke="#00544B" strokeMiterlimit={10} />
    <path
      d="M8 15c1.675 0 3.033-3.134 3.033-7S9.675 1 8 1C6.325 1 4.967 4.134 4.967 8S6.325 15 8 15ZM1 8h14M8 1v14"
      stroke="#00544B"
      strokeMiterlimit={10}
    />
  </svg>
);
export default SvgWeb;
