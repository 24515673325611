import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SimpleDosing, FastResponse, EffectiveTherapy, PredictableResults } from 'assets/svgs';

function Header({ graphic }) {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      delay: 2,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { opacity: 0, x: '-25px' },
    show: { opacity: 1, x: '0' }
  };
  return (
    <InnerContainer>
      <Content>
        <Title>
          A step ahead in
          <br />
          <span>VITAMIN D</span> deﬁciency
          <sup>
            <small>1</small>
          </sup>
        </Title>
        <Statements variants={container} initial="hidden" animate="show">
          <Statement key={1} variants={item}>
            <SimpleDosing />
            <p>
              Simple
              <br />
              <span>Dosing</span>
              <sup>
                <span>2</span>
              </sup>
            </p>
          </Statement>
          <Statement key={2} variants={item}>
            <FastResponse />
            <p>
              Fast
              <br />
              <span>Response</span>
              <sup>
                <span>3</span>
              </sup>
            </p>
          </Statement>
          <Statement key={3} variants={item}>
            <EffectiveTherapy />
            <p>
              Effective
              <br />
              <span>Therapy</span>
              <sup>
                <span>2,3</span>
              </sup>
            </p>
          </Statement>
          <Statement key={4} variants={item}>
            <PredictableResults />
            <p>
              Predictable
              <br />
              <span>Results</span>
              <sup>
                <span>4</span>
              </sup>
            </p>
          </Statement>
        </Statements>
      </Content>
      <PersonMeshAnimationWrapper>
        <PersonMeshDesktop image={getImage(graphic)} alt="person mesh" />
      </PersonMeshAnimationWrapper>
    </InnerContainer>
  );
}

const InnerContainer = styled.header`
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  min-height: 100vh;
  padding: 0 1.25rem;
  position: relative;
  @media screen and (min-width: 64rem) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 0;
    max-height: 1152px;
  }
  @media screen and (max-height: 46rem) {
    margin-top: 5rem;
  }
`;

const Content = styled.div`
  align-self: end;
  display: flex;
  flex-direction: column;
  gap: 2.875rem;
  grid-column: 1/7;
  margin-top: 6rem;
  padding-bottom: 3rem;
  @media screen and (min-width: 64rem) {
    align-self: end;
    grid-column: 2/9;
    margin: 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/8;
    margin-bottom: 6rem;
  }
`;

const Title = styled.h2`
  font-size: 3rem;
  font-style: italic;
  font-weight: 300;
  span {
    color: var(--dnord-secondary);
    font-weight: 700;
  }
  @media screen and (min-width: 23rem) {
    font-size: 3.75rem;
  }
  @media screen and (min-width: 48rem) {
    font-size: 4.25rem;
  }
`;

const Statements = styled(motion.div)`
  display: grid;
  gap: 2.875rem;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (min-width: 48rem) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Statement = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-style: italic;
  gap: 0.75rem;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  span {
    color: var(--dnord-teritary);
    font-weight: 700;
  }
  svg {
    line-height: 1;
    max-width: 146px;
  }
`;

const Subtitle = styled.h3`
  color: var(--dnord-teritary);
  font-size: 2.25rem;
  font-style: italic;
  padding: 0 1.25rem;
  span {
    color: var(--dnord-secondary);
    font-size: 1.25rem;
  }
  sup {
    font-size: 1.25rem;
  }
  svg {
    height: 120px;
    width: 120px;
  }
  @media screen and (min-width: 64rem) {
    font-size: 3rem;
    white-space: nowrap;
  }
`;

const PersonMeshAnimationWrapper = styled(motion.div).attrs({
  initial: { opacity: 0, x: 200, y: 200 },
  animate: { opacity: 1, x: 0, y: 0 },
  transition: {
    delay: 0.5,
    ease: 'easeOut',
    opacity: { duration: 0.5 },
    x: { duration: 1 },
    y: { duration: 1 }
  }
})`
  bottom: -100px;
  right: 0;
  position: absolute;
  z-index: -1;
`;

const PersonMeshDesktop = styled(GatsbyImage)`
  min-width: 64rem;
  width: 84.5vw;
  @media screen and (min-width: 48rem) {
    width: 100vw;
    min-width: 90rem;
  }
  @media screen and (min-width: 64rem) {
    max-width: 121.875rem;
    min-width: 75.938rem;
    width: 84.5vw;
  }
`;

export default Header;
