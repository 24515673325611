import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ProductBackground, Dnord, Monthly } from 'assets/svgs';

function WhoCanTake() {
  return (
    <InnerContainer
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}>
      <LargeText>
        <h4>Who can take</h4>
        <Dnord />
      </LargeText>
      <Title>
        Always take this medicine exactly as your doctor has told you.
        <br />
        Check with your doctor, nurse or pharmacist if you are not sure.
      </Title>
      <Subtitle>
        <p>
          <Dnord /> is recommended for the following patients who are over 18 years of age:
        </p>
      </Subtitle>
      <MainContent>
        <ProductImage>
          <ProductBackground />
          <Image>
            <Monthly />
          </Image>
        </ProductImage>
        <TextContent>
          <Paragraph width="55%">
            <span>Treatment</span> of vitamin D deficiency
            <br />
            (i.e., 25(OH)D levels &#x3c; 25 nmol/L) in adults
          </Paragraph>
          <Paragraph width="85%">
            <span>Prevention</span> of vitamin D deficiency in adults with identified risks such as
            in patients with malabsorption syndrome, chronic kidney disease mineral and bone
            disorder (CKD-MBD) or other identified risks
          </Paragraph>
          <Paragraph width="65%">
            As <span>adjuvant</span> for the specific treatment of osteoporosis in patients with
            vitamin D deficiency or at risk of vitamin D deficiency
          </Paragraph>
        </TextContent>
      </MainContent>
    </InnerContainer>
  );
}

const InnerContainer = styled(motion.section)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  justify-content: center;
  margin: 3rem 0;
  padding: 0 1.25rem;
  @media screen and (min-width: 64rem) {
    gap: 3rem;
    grid-column: 2/12;
    margin: 6rem 0;
    padding: 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const LargeText = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  width: 100%;
  h4 {
    font-size: 2rem;
    font-style: italic;
    font-weight: 300;
  }
  svg {
    width: 125px;
  }
  sup {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 64rem) {
    svg {
      width: 194px;
    }
    h4 {
      font-size: 3rem;
    }
  }
`;

const Title = styled.h3`
  color: var(--dnord-teritary);
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 48rem) {
    font-size: 1.375rem;
  }
  @media screen and (min-width: 64rem) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 300;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1rem;
  svg {
    width: 120px;
  }
`;

const ProductImage = styled.div`
  align-self: center;
  height: 257px;
  min-width: 264px;
  position: relative;
  width: 264px;
  svg {
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 64rem) {
    height: 357px;
    min-width: 364px;
    width: 364px;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media screen and (min-width: 64rem) {
    flex-direction: row;
  }
`;

const Image = styled.div`
  height: auto;
  left: 52%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 72%;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 300;
  gap: 2.25rem;
  width: 100%;
  span {
    color: var(--dnord-secondary);
    font-weight: 700;
  }
`;

const Paragraph = styled.p`
  position: relative;
  &:before {
    background: linear-gradient(90deg, #00544b 0%, #c9d688 43%, #ff8c1a 69%, #f46437 100%);
    bottom: -0.75rem;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    width: ${({ width }) => width};
  }
`;

export default WhoCanTake;
