import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Dnord, Monthly } from 'assets/svgs';

function HowToTake() {
  return (
    <InnerContainer
      id="how-to-take"
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}>
      <LargeText>
        <h4>How to take</h4>
        <Dnord />
      </LargeText>
      <Title>
        Always take this medicine exactly as your doctor has told you.
        <br />
        Check with your doctor, nurse or pharmacist if you are unsure!
      </Title>
      <Grid>
        <Item gridArea="first" style={{ flexDirection: 'row' }}>
          <span>Do not take more</span>
          <Dnord />
          <span>or more often than what has been prescribed.</span>
        </Item>
        <Item gridArea="second">
          <span>
            There are populations at high risk of vitamin D deficiency which may need to take higher
            doses, after analytical verification of the extent of the deficiency, <br />
            the doctor may consider a dose of one capsule every two weeks or every week.
            <br />
            <strong>This medicine should not be administered with a daily frequency.</strong>
            <br />
            Your doctor should monitor your calcium and vitamin D levels periodically, usually
            before starting the treatment and after <strong>3-4 months</strong>.
          </span>
        </Item>
        <Item gridArea="third">
          <Dnord />
          <span>must be swallowed whole</span>
        </Item>
        <Item gridArea="fourth">
          <Dnord />
          <span>can be taken with water</span>
        </Item>
        <Item gridArea="fifth">
          <Dnord />
          <span>can be taken any time of the day</span>
        </Item>
        <Item gridArea="sixth" style={{ gap: '3rem' }}>
          <h3>The recommended dose is 1 CAPSULE, once a MONTH</h3>
          <Monthly />
        </Item>
      </Grid>
    </InnerContainer>
  );
}

const InnerContainer = styled(motion.section)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  justify-content: center;
  margin: 3rem 0;
  padding: 0 1.25rem;
  @media screen and (min-width: 64rem) {
    gap: 3rem;
    grid-column: 2/12;
    margin: 0 0 8rem 0;
    padding: 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const LargeText = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  width: 100%;
  h4 {
    font-size: 2rem;
    font-style: italic;
    font-weight: 300;
  }
  svg {
    width: 125px;
  }
  sup {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 64rem) {
    svg {
      width: 194px;
    }
    h4 {
      font-size: 3rem;
    }
  }
`;

const Title = styled.h3`
  color: var(--dnord-teritary);
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 48rem) {
    font-size: 1.375rem;
  }
  @media screen and (min-width: 64rem) {
    font-size: 1.5rem;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  gap: 2rem;
  width: 100%;
  @media screen and (min-width: 48rem) {
    font-size: 1.375rem;
  }
  @media screen and (min-width: 64rem) {
    display: grid;
    font-size: 1rem;
    gap: 1.875rem 3.125rem;
    grid-template-areas:
      'first first first'
      'second second second'
      'third fourth fifth'
      'sixth sixth sixth';
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 180px auto auto;
  }
`;

const Item = styled.div`
  align-items: center;
  background: white;
  border-radius: 2px;
  box-shadow: 4px 4px 26px 2px rgba(0, 45, 38, 0.2);
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  grid-area: ${({ gridArea }) => gridArea};
  justify-content: center;
  padding: 2.5rem;
  h3 {
    color: var(--dnord-teritary);
    font-size: 1.125rem;
    font-style: italic;
    text-align: center;
  }
  svg {
    width: 98px;
  }
  span {
    color: #707070;
    font-family: 'source-sans-pro', sans-serif;
    text-align: center;
  }
  @media screen and (min-width: 23rem) {
    h3 {
      font-size: 1.5rem;
    }
  }
`;

export default HowToTake;
