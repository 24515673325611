import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Button } from 'components';

function SupportingMaterials({ title, materials }) {
  return (
    <InnerContainer
      id="supporting-materials"
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}>
      <Title>{title}</Title>
      <Materials>
        {materials.map((material, index) => (
          <Item key={index}>
            <p>{material.title}</p>
            <Button
              onClick={() => window.open(material.link, '_blank')}
              color="var(--dnord-teritary)"
              download={material.type === 'download'}>
              {material.type === 'download' ? 'Download' : 'Visit For More'}
            </Button>
          </Item>
        ))}
      </Materials>
    </InnerContainer>
  );
}

const InnerContainer = styled(motion.section)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  justify-content: center;
  margin: 2rem 0;
  padding: 0 1.25rem;
  @media screen and (min-width: 64rem) {
    gap: 3rem;
    grid-column: 2/12;
    margin: 4rem 0 6rem 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/11;
  }
`;

const Title = styled.h3`
  color: var(--dnord-teritary);
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
`;

const Materials = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  @media screen and (min-width: 48rem) {
    flex-flow: row wrap;
  }
  @media screen and (min-width: 64rem) {
    flex-flow: nowrap;
  }
`;

const Item = styled.div`
  align-items: center;
  border-radius: 2px;
  box-shadow: 4px 4px 26px 2px rgba(0, 45, 38, 0.2);
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  gap: 1.25rem;
  height: 100%;
  justify-content: space-between;
  line-height: auto;
  padding: 2rem;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 48rem) {
    max-width: 220px;
  }
`;

export default SupportingMaterials;
