import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { motion, useMotionValueEvent } from 'framer-motion';
import { DnordCalcifediolLogoSvg, ArrowRight, MenuIcon } from 'assets/svgs';
import { AnchorLink as Anchor } from 'gatsby-plugin-anchor-links';
import DropdownNavigation from './DropdownNavigation';
import { useWindowSize } from '../../../hooks';

function Navigation({ location, dropdownOpen, setDropdownOpen, scrollYProgress }) {
  const [hidden, setHidden] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const { windowWidth } = useWindowSize();

  function update() {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
  }

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -120 }
  };

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    setScrollY(latest);
    update();
  });

  return (
    <Container
      id="navigation"
      variants={variants}
      animate={hidden ? 'hidden' : 'visible'}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}>
      {windowWidth > 1024 && (
        <div
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onBlur={() => null}
          onFocus={() => null}>
          <PageChangeSlide
            type="button"
            invert={location.pathname.includes('patient')}
            hovered={hovered}
            onClick={() => {
              navigate(location.pathname.includes('patient') ? '/hcp' : '/patient');
              setDropdownOpen(false);
            }}>
            <div>
              <p>
                {location.pathname.includes('patient') ? 'Go To HCP Page' : 'Go To Patient Page'}
              </p>
              <ArrowRight width={14} height={10} />
            </div>
          </PageChangeSlide>
          <PageChange
            type="button"
            invert={location.pathname.includes('patient')}
            onClick={() => {
              navigate(location.pathname.includes('patient') ? '/hcp' : '/patient');
              setDropdownOpen(false);
            }}>
            {location.pathname.includes('patient')
              ? 'Patient Info'
              : 'Healthcare Professional Info'}
          </PageChange>
        </div>
      )}
      <LogoContainer role="button" onClick={() => navigate('/')}>
        <DnordCalcifediolLogoSvg />
      </LogoContainer>
      {location.pathname.includes('hcp') ? (
        <Links>
          <AnchorLink to="/hcp#indications">Indications</AnchorLink>
          <AnchorLink to="/hcp#treatment">Treatment and Dosing</AnchorLink>
          <AnchorLink to="/hcp#clinical-data">Clinical Data</AnchorLink>
          <AnchorLink to="/hcp#role">Role of Vitamin D</AnchorLink>
          <AnchorLink to="/hcp#supporting-materials">Support Tools</AnchorLink>
        </Links>
      ) : (
        <Links>
          <AnchorLink to="/patient#what-is-dnord">What is Dnord?</AnchorLink>
          <AnchorLink to="/patient#how-to-take">How to take Dnord?</AnchorLink>
          <AnchorLink to="/patient#reminder">Reminder</AnchorLink>
          <AnchorLink to="/patient#faq">FAQ’s</AnchorLink>
          <AnchorLink to="/patient#supporting-materials">Further Information</AnchorLink>
        </Links>
      )}
      {windowWidth < 1024 && (
        <>
          <MenuToggle
            dropdownOpen={dropdownOpen}
            dark={!dropdownOpen}
            onClick={() => {
              setDropdownOpen((previousState) => !previousState);
              if (dropdownOpen) {
                document.body.classList.toggle('no-scroll');
              }
            }}>
            <MenuIcon dropdownOpen={dropdownOpen} />
          </MenuToggle>
          {dropdownOpen && (
            <DropdownNavigation
              dropdownOpen={dropdownOpen}
              setDropdownOpen={setDropdownOpen}
              location={location}
            />
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled(motion.nav)`
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: 80px;
  padding: 0 1.25rem;
  position: fixed;
  top: 0;
  transition: top 0.3s;
  width: 100%;
  z-index: 120;
  @media screen and (min-width: 48rem) {
    padding: 0 1.25rem;
    height: 180px;
  }
  @media screen and (min-width: 64rem) {
    background: none;
    position: absolute;
    padding: 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const LogoContainer = styled(motion.div).attrs({
  initial: { opacity: 0, x: -250 },
  animate: { opacity: 1, x: 0 },
  transition: { opacity: { duration: 0.2 }, x: { duration: 0.5 } }
})`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  grid-column: 1 / 3;
  height: 100%;
  justify-content: center;
  position: relative;
  z-index: 102;
  svg {
    height: auto;
    width: 100%;
  }
  @media screen and (min-width: 64rem) {
    grid-column: 2 / 4;
    padding: 1.25rem 0;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 3/4;
  }
`;

const Links = styled.div`
  align-items: center;
  display: none;
  gap: 2rem;
  grid-column: 5 / 12;
  justify-content: flex-end;
  @media screen and (min-width: 64rem) {
    display: flex;
  }
  @media screen and (min-width: 108rem) {
    grid-column: 5/11;
  }
`;

const AnchorLink = styled(Anchor)`
  color: var(--dnord-default-font-color);
  cursor: pointer;
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  text-decoration: none;
`;

const MenuToggle = styled(motion.button)`
  align-items: center;
  background: transparent;
  display: flex;
  grid-column: 6 / 7;
  justify-content: center;
  justify-self: end;
  padding: ${({ dropdownOpen }) => (dropdownOpen ? '1.5rem 1.25rem' : '2rem 1.25rem')};
  position: relative;
  transition: all 0.3s ease-out;
  &:before {
    background: var(--dnord-secondary);
    border-radius: 0.25rem;
    content: '';
    height: 3rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    z-index: 101;
  }
  svg {
    height: auto;
    width: 1.125rem;
    z-index: 102;
  }
  @media screen and (min-width: 48rem) {
    padding: ${({ dropdownOpen }) => (dropdownOpen ? '2.5rem 1.25rem' : '3rem 1.25rem')};
  }
`;

const PageChange = styled(motion.button)`
  align-items: center;
  background: ${({ invert }) => (invert ? 'var(--dnord-secondary)' : 'var(--dnord-teritary)')};
  border-radius: 30px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 60px;
  justify-content: center;
  left: 50%;
  line-height: 1;
  padding: 1.5rem 2.5rem 0 2.5rem;
  position: absolute;
  text-transform: uppercase;
  text-wrap: nowrap;
  top: -30px;
  transform: translate(-50%, 0);
  width: 280px;
  z-index: 101;
`;

const PageChangeSlide = styled(motion.button)`
  align-items: center;
  background: ${({ invert }) => (invert ? 'var(--dnord-secondary)' : 'var(--dnord-teritary)')};
  border-radius: 30px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 60px;
  justify-content: center;
  left: 50%;
  line-height: 1;
  position: absolute;
  text-transform: uppercase;
  text-wrap: nowrap;
  top: -30px;
  transform: translate(-50%, 0);
  width: 280px;
  z-index: 100;
  div {
    background: ${({ invert }) => (invert ? 'var(--dnord-teritary)' : 'var(--dnord-secondary)')};
    border-radius: 30px;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    height: 100%;
    left: ${({ hovered, invert }) =>
      hovered ? 'calc(100% - 50px)' : !hovered && invert ? '110px' : '80px'};
    padding: 2.25rem 1rem 0 3.5rem;
    position: absolute;
    transition: all 0.5s ease-in-out;
    width: max-content;
    svg {
      height: 10px;
      margin-left: 0.75rem;
      width: 14px;
    }
  }
`;

export default Navigation;
